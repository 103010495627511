import React from 'react'

const calculateShiftWidth = (hours) => {
  return `${Math.min((hours / 8) * 100, 100)}%`;
}

const ShiftContainer = ({textColor = "text-plum", bgColor = "bg-white", width = "100%", children}) => {
  return (
    <div
      className={`flex h-12 w-full items-center gap-2 overflow-hidden px-2 text-sm leading-none shadow rounded-md ${bgColor} ${textColor}`}
      style={{ width }}
    >
      {children}
    </div>
  );
}

const PreviousShift = ({title, totalTimesheetHours}) => {
  let textColor

  if (totalTimesheetHours > 8) {
    textColor = 'text-warning'
  } else if (totalTimesheetHours < 7) {
    textColor = 'text-caution'
  }

  return (
    <ShiftContainer textColor={textColor} width={calculateShiftWidth(totalTimesheetHours)}>
      <div className="truncate font-base-bold">{totalTimesheetHours}hr @ {title}</div>
    </ShiftContainer>
  );
};

const ScheduledShift = ({ title, totalShiftHours }) => {
  return (
    <ShiftContainer width={calculateShiftWidth(totalShiftHours)}>
      <div className="truncate font-base-bold">{totalShiftHours}hr @ {title}</div>
    </ShiftContainer>
  );
};

const PTO = ({ title }) => {
  return (
    <ShiftContainer bgColor="bg-notification" textColor="text-white">
      <div className="w-full overflow-hidden">
        <div className="truncate font-base-bold">{title}</div>
        <div className="truncate font-base-medium text-xs text-plum/60">PTO</div>
      </div>
    </ShiftContainer>
  );
};

const NoShift = ({ event }) => {
  const {
    extendedProps: { locationId, craftworker: { id } },
    startStr,
    endStr
  } = event;

  return (
    <div className="h-full p-1">
      <a href={`/shifts/new?modal=true&craftworker_id=${id}&starts_at=${startStr}&ends_at=${endStr}`} data-turbo-frame="modal">
        <div className="h-12 w-full items-center overflow-hidden rounded-md border bg-caution p-2 px-2 font-base-medium text-sm leading-none text-white shadow-sm">
          <div className="truncate font-base-bold">No Shift Assigned</div>
          <div className="truncate text-xs">Click to add a shift on Deputy</div>
        </div>
      </a>
    </div>
  );
};

export const Event = ({ eventInfo }) => {
  const { title, extendedProps, display } = eventInfo.event;
  const {
    id,
    locationId,
    customerId,
    location,
    totalTimesheetHours,
    totalShiftHours,
    pto,
  } = extendedProps;
  const noShift = display === 'background' || totalShiftHours === 0;
  const previousShift = totalTimesheetHours > 0 && !pto;

  if (noShift) {
    return <NoShift event={eventInfo.event} />;
  }

  if (pto) {
    return <PTO title={title}/>;
  }

  if (previousShift) {
    return <PreviousShift title={title} totalTimesheetHours={totalTimesheetHours} />;
  }

  return <ScheduledShift title={title} totalShiftHours={totalShiftHours} />;
};
