import { Controller } from '@hotwired/stimulus';

const CABINET = 'CabinetSet';
const NOT_APPLICABLE = 'not_applicable';
const NULL_STRING = 'null'; // This is a string because it comes from the data attribute
export default class extends Controller {
  static targets = [
    'form',
    'area',
    'sheenField',
    'sheen',
    'itemType',
    'price',
    'priceField',
    'unitLabel',
    'free',
    'submitButtons',
    'optionalField',
    'selectedField',
    'optionalFieldCheckbox',
    'selectedFieldCheckbox',
  ];

  connect() {
    this.itemTypesByArea = JSON.parse(this.formTarget.dataset.itemTypesByArea);
    this.estimateId = this.formTarget.dataset.estimateId;
    this.changeArea();
  }

  validate() {
    const areaType = this.getAreaType();
    const itemTypeId = this.itemTypeTarget.value;
    const sheen =
      this.sheenTarget.options[this.sheenTarget.selectedIndex].value;
    const price = this.priceTarget.value;
    const defaultPrice = this.priceTarget.dataset.defaultPrice;
    const free = this.freeTarget.checked;

    if (areaType === CABINET && (sheen === '' || sheen === NOT_APPLICABLE)) {
      this.toggleDisabledSubmitButtons(true);
      return;
    }

    if (itemTypeId === '') {
      this.toggleDisabledSubmitButtons(true);
      return;
    }

    this.toggleDisabledSubmitButtons(false);
  }

  changeArea() {
    const areaType = this.getAreaType();

    if (areaType === CABINET) {
      this.sheenFieldTarget.classList.remove('hidden');
      this.optionalFieldCheckboxTarget.checked = false;
      this.selectedFieldCheckboxTarget.checked = true;
    } else {
      this.sheenFieldTarget.classList.add('hidden');
    }

    // If the selected item type is not valid for the new area type, clear it
    const valid = this.itemTypesByArea[areaType].find(
      (itemType) => itemType.id.toString() === this.itemTypeTarget.value,
    );

    if (!valid) {
      this.itemTypeTarget.value = '';
    }

    this.setPrice();
    this.filterItemTypes(areaType);
  }

  changeItemType() {
    this.setUnit();
    this.setPrice();
  }

  togglePriceField() {
    if (this.freeTarget.checked) {
      this.priceFieldTarget.classList.add('hidden');
    } else {
      this.priceFieldTarget.classList.remove('hidden');
    }
    this.validate();
  }

  toggleSelectedField() {
    if (this.optionalFieldCheckboxTarget.checked) {
      this.selectedFieldCheckboxTarget.disabled = false;
    } else {
      this.selectedFieldCheckboxTarget.checked = true;
      this.selectedFieldCheckboxTarget.disabled = true;
    }
  }

  setUnit() {
    if (this.itemTypeTarget.value.includes('sqft')) {
      this.unitLabelTarget.innerText = 'SQFT';
      this.priceFieldTarget.querySelector('label').innerText = 'Price per SQFT';
    } else {
      this.unitLabelTarget.innerText = 'Quantity';
      this.priceFieldTarget.querySelector('label').innerText = 'Price';
    }
  }

  async setPrice() {
    const defaultPrice = await this.updatePrice();
    const priceField = this.priceTarget;
    if (defaultPrice) {
      priceField.dataset.defaultPrice = defaultPrice;
      if (priceField.value === '') {
        priceField.placeholder = defaultPrice.toString();
      } else if (priceField.value === '0.0') {
        priceField.value = '';
        priceField.placeholder = defaultPrice.toString();
      } else if (parseFloat(priceField.value) > 0) {
        priceField.placeholder = defaultPrice.toString();
      }
    } else {
      priceField.dataset.defaultPrice = null;
      if (this.itemTypeTarget.value === '') {
        priceField.placeholder = 'Choose an item type';
        priceField.value = '';
      } else {
        priceField.placeholder = 'Specify a price';
      }
    }
    this.validate();
  }

  async updatePrice() {
    const areaType = this.getAreaType();
    const paintableSgid = this.getAreaSgid();
    const sheen =
      this.sheenTarget.options[this.sheenTarget.selectedIndex].value;
    const itemTypeId = this.itemTypeTarget.value;

    if (itemTypeId === '') return null;
    // no cabinet pricing without a sheen
    if (areaType === CABINET && (sheen === '' || sheen === NOT_APPLICABLE))
      return null;

    return await this.getDefaultPrice(paintableSgid, itemTypeId, sheen);
  }

  filterItemTypes(areaType) {
    const itemTypesToShow = (this.itemTypesByArea[areaType] || []).map(
      (itemType) => itemType.id.toString(),
    );

    Array.from(this.itemTypeTarget.options).forEach((option) => {
      const optionValue = option.value;
      if (areaType == 'Estimate' || itemTypesToShow.includes(optionValue)) {
        option.hidden = false;
      } else {
        option.hidden = true;
      }
    });
  }

  getAreaType() {
    return this.areaTarget.options[this.areaTarget.selectedIndex].dataset
      .areaType;
  }

  getAreaSgid() {
    return this.areaTarget.options[this.areaTarget.selectedIndex].value;
  }

  async getDefaultPrice(paintableSgid, itemTypeId, sheen) {
    try {
      const response = await fetch(
        `/api/v1/estimator/estimate_item?itemTypeId=${itemTypeId}&sheen=${sheen}&estimateId=${this.estimateId}&paintableSgid=${paintableSgid}`,
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const { amountInDollars } = await response.json();
      return amountInDollars;
    } catch (error) {
      console.error('Failed to fetch default price:', error);
      return null; // or handle the error as appropriate
    }
  }

  toggleDisabledSubmitButtons(validate) {
    this.submitButtonsTargets.forEach((button) => {
      button.disabled = validate;
    });
  }
}
