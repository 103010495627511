import React from 'react';
import { CountAvatar, Avatar } from '../ui/Avatars';
import { Tooltip } from 'react-tippy';
import ProjectTypeBadge from '../ProjectTypeBadge';

// handle non-project events
// handle eventInfo.event.extendedProps.status === 'proposed'
// handle eventInfo.event.extendedProps.eventType === 'project'
// handle eventInfo.event.extendedProps.eventType !== 'projects'

const TooltipContent = ({ eventInfo }) => {
  const {
    balance,
    painters,
    customerName,
    potential,
    projectId,
    internalId,
    projectType,
    customTitle,
    revenue,
    salesStatus,
  } = eventInfo.event.extendedProps;

  const formatCurrency = (value) => {
    const dollarValue = value / 100;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(dollarValue);
  };

  return (
    <div className="space-y-2 rounded-md bg-plum p-4 text-sm text-white shadow max-h-[calc(100svh_-_64px)] overflow-y-auto">
      <div className="flex items-center gap-2">
        <ProjectTypeBadge projectType={projectType} />
        <div>
          <div className="truncate font-base-medium text-xs text-white">
            {customTitle ? (
              <span>{customTitle}</span>
            ) : (
              <span>{internalId}</span>
            )}
          </div>
          <h4>{customerName}</h4>
        </div>
      </div>
      <hr className="opacity-20" />
      <ul>
        <li className="flex gap-2 font-base-medium capitalize">
          <strong>Status:</strong> {salesStatus}
        </li>
        <li className="flex gap-2 font-base-medium capitalize">
          <strong>Value:</strong> {formatCurrency(potential)}
        </li>
        <li className="flex gap-2 font-base-medium capitalize">
          <strong>Balance:</strong> {formatCurrency(balance)}
        </li>
      </ul>
      <hr className="opacity-20" />
      <h4>Crew</h4>
      <ul className="space-y-2">
        {painters && painters.length > 0 ? (
          painters.map((painter) => (
            <li className="flex items-center gap-2" key={painter.id}>
              <Avatar user={painter} tooltips={false} />
              <span className="font-base-medium">
                {painter.firstName} {painter.lastName}
              </span>
            </li>
          ))
        ) : (
          <li>No painters assigned</li>
        )}
      </ul>
    </div>
  );
};

export const Event = ({ eventInfo }) => {
  const {
    balance,
    painters,
    customerName,
    potential,
    projectId,
    internalId,
    projectType,
    customTitle,
    revenue,
    salesStatus,
  } = eventInfo.event.extendedProps;

  return (
    <div className="flex h-10 md:h-12 w-full items-center gap-2 rounded-md bg-white px-1 md:px-2 text-sm leading-none shadow outline-gray-200 transition-all duration-200 @container/event hover:bg-gray-25 hover:outline">
      <Tooltip html={<TooltipContent eventInfo={eventInfo} />} interactive >
        <ProjectTypeBadge projectType={projectType} />
      </Tooltip>

      <div className="flex-1 overflow-hidden">
        <div className="truncate font-base-medium text-xs text-plum/60">
          {customTitle ? <span>{customTitle}</span> : <span>{internalId}</span>}
        </div>
        <div className="truncate font-base-bold">{customerName}</div>
      </div>
      {painters && painters.length > 0 && (
        <CountAvatar users={painters} classes="hidden @3xs/event:flex" />
      )}
      {salesStatus !== 'won' && (
        <span className="badge bg-gray-400">{salesStatus}</span>
      )}
    </div>
  );
};
