import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = [
    'navbar',
    'text',
    'divider',
    'logo',
    'navlist',
    'avatar',
    'header',
  ];
  static values = {
    isMobile: Boolean,
    collapsed: Boolean,
    collapsedPreference: Boolean,
    shouldBeCollapsed: Boolean,
  };

  connect() {
    this.collapsedPreferenceValue = localStorage.getItem('collapsedPreference');
    this.isMobileValue = this.checkIsMobile();
    this.shouldBeCollapsedValue = this.checkShouldBeCollapsed();

    this.updateCollapseState(this.shouldBeCollapsedValue);

    window.addEventListener('resize', () => this.initialize());
  }

  disconnect() {
    window.removeEventListener('resize', () => this.initialize());
  }

  initialize() {
    this.isMobileValue = this.checkIsMobile();
    this.shouldBeCollapsedValue = this.checkShouldBeCollapsed();
    if (this.shouldBeCollapsedValue !== this.collapsedValue)
      this.updateCollapseState(this.shouldBeCollapsedValue);
  }

  checkIsMobile() {
    const isMobile = window.innerWidth <= 1024;
    return isMobile;
  }

  checkShouldBeCollapsed() {
    if (this.checkIsMobile()) {
      return true;
    }

    if (
      this.collapsedPreferenceValue !== null &&
      this.collapsedPreferenceValue !== undefined
    ) {
      return this.collapsedPreferenceValue;
    }

    return true;
  }

  updatePreference(pref) {
    localStorage.setItem('collapsedPreference', pref);
  }

  updateCollapseState(shouldBeCollapsed) {
    if (shouldBeCollapsed) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  addTransitionAttributes() {
    const elements = [
      ...this.textTargets,
      ...this.dividerTargets,
      ...this.headerTargets,
    ];

    elements.forEach((element) => {
      element.setAttribute(
        'data-transition-enter',
        'transition-all duration-300 ease-out',
      );
      element.setAttribute(
        'data-transition-enter-start',
        'opacity-0 transform -translate-x-4',
      );
      element.setAttribute(
        'data-transition-enter-end',
        'opacity-100 transform translate-x-0',
      );
      element.setAttribute(
        'data-transition-leave',
        'transition-all duration-300 ease-in',
      );
      element.setAttribute(
        'data-transition-leave-start',
        'opacity-100 transform translate-x-0',
      );
      element.setAttribute(
        'data-transition-leave-end',
        'opacity-0 transform -translate-x-4',
      );
    });
  }

  removeTransitionAttributes() {
    const elements = [
      ...this.textTargets,
      ...this.dividerTargets,
      ...this.headerTargets,
    ];

    elements.forEach((element) => {
      element.removeAttribute('data-transition-enter');
      element.removeAttribute('data-transition-enter-start');
      element.removeAttribute('data-transition-enter-end');
      element.removeAttribute('data-transition-leave');
      element.removeAttribute('data-transition-leave-start');
      element.removeAttribute('data-transition-leave-end');
    });
  }

  toggle() {
    if (this.isMobileValue) return;

    if (this.collapsedValue) {
      this.expand();
    } else {
      this.collapse();
    }
    this.updatePreference(this.collapsedValue);
  }

  collapse() {
    this.addTransitionAttributes();

    this.textTargets.forEach((text) => {
      leave(text);
    });
    this.dividerTargets.forEach((divider) => {
      divider.classList.add('my-3');
      enter(divider);
    });

    this.navbarTarget.classList.add('w-16');
    this.navbarTarget.classList.remove('w-56');

    setTimeout(() => { }, 300);

    this.headerTargets.forEach((header) => {
      header.classList.remove('mb-2', 'ml-2', 'mt-8');
      leave(header);
    });

    this.avatarTarget.classList.remove('w-14', 'h-14');
    this.avatarTarget.classList.add('w-8', 'h-8');

    this.collapsedValue = true;
  }

  expand() {
    this.navbarTarget.classList.add('w-56');
    this.navbarTarget.classList.remove('w-16');
    this.logoTarget.classList.remove('w-6');
    this.dividerTargets.forEach((divider) => {
      divider.classList.remove('my-3');
    });
    this.headerTargets.forEach((header) => {
      header.classList.add('mb-2', 'ml-2', 'mt-8');
      enter(header);
    });

    setTimeout(() => {
      this.textTargets.forEach((text) => {
        enter(text);
      });
    }, 300);
    this.dividerTargets.forEach((divider) => {
      leave(divider);
    });

    this.avatarTarget.classList.remove('w-8', 'h-8');
    this.avatarTarget.classList.add('w-14', 'h-14');

    this.collapsedValue = false;

    this.removeTransitionAttributes();
  }

  hide() {
    if (!this.isMobileValue) return;
    this.navbarTarget.classList.add('-translate-x-56');
    this.collapse();
  }

  show() {
    if (!this.isMobileValue) return;
    this.navbarTarget.classList.remove('-translate-x-56');
    this.expand();
  }
}
