import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="global"
export default class extends Controller {
  connect() {}

  togglePinnedAndFocus(event) {
    const targetElement = document.querySelector(
      '[data-controller="comment-textbox"]',
    );
    const textboxController =
      this.application.getControllerForElementAndIdentifier(
        targetElement,
        'comment-textbox',
      );

    if (textboxController) {
      textboxController.togglePinnedAndFocus();
    } else {
      console.error('Textbox controller not found');
    }
  }
}
