import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset-form"
export default class extends Controller {
  static targets = [ "clear" ]

  connect() {
  }

  reset() {
    if(this.clearTargets.length > 0) {
      this.clearTargets.forEach((target) => {
        target.value = "";
      });
    } else {
      this.element.reset();
    }
  }
}
