import React from 'react';
import { NoSymbolIcon, ClockIcon } from "@heroicons/react/24/outline";

export const ResourceHeader = ({ args, currentEvents }) => {
  const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);
  // calculate aggregate stats for all craftworkers during this time range.
  const hoursWorked = sum(
    currentEvents.map((evt) => evt.extendedProps.totalTimesheetHours),
  ).toFixed(1);
  const hoursBooked = sum(
    currentEvents.map((evt) => evt.extendedProps.totalShiftHours),
  ).toFixed(1);
  const ptoHours = sum(
    currentEvents
      .filter((evt) => evt.extendedProps.pto)
      .map((evt) => evt.extendedProps.totalShiftHours),
  ).toFixed(1);

  return (
    <div className="flex flex-col gap-1 text-xs font-base-medium">
      <div className="flex items-center gap-1">
        Hours: {hoursWorked} / {hoursBooked}
      </div>
      <div className="flex items-center gap-1">
        PTO: {ptoHours}
      </div>
    </div>
  );
};
