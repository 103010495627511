import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clickable"
export default class extends Controller {
  static values = {
    url: String,
    target: String
  }

  connect() {
    this.element.addEventListener("click", this.click.bind(this))
  }

  click(e) {
    if (this.targetValue) {
      window.open(this.urlValue, this.targetValue);
      return;
    }

    if (e.metaKey) {
      // CMD+Click: Open in a new tab
      window.open(this.urlValue, '_blank');
    } else {
      // Regular click: Navigate in the same window
      window.location.href = this.urlValue;
    }
  }
}
