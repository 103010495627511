import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-selector"
export default class extends Controller {
  static targets = ["menu", "field"]

  async selectUser(event) {
    this.fieldTarget.value = event.currentTarget.dataset.id
    Turbo.navigator.submitForm(this.element)
  }
}
