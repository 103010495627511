import React from 'react';
import { AvatarGroup, CountAvatar } from "../ui/Avatars";
import { StackedDate } from "../ui/StackedDate";
import { WeatherDayIcon } from "../ui/WeatherDayIcon";

export const DateHeader = ({weatherDays, staffingResources, date, text, isFuture }) => {
  const day = text.split(' ')[0];
  const weekday = text.split(' ')[1];
  const key = date.toISOString().split('T')[0];

  const craftworkers = staffingResources
    .filter((resource) => resource.start.split('T')[0] === key)
    .map((resource) => resource.extendedProps.craftworker);

  const weatherDay = weatherDays.find(({ day }) => day === key);
  const openCraftworkerOpeningsModal = () => {
    Turbo.visit(
      `/calendar_events?craftworker_openings=true&modal=true&start=${date}&end=${date}`, {
        frame: 'modal',
      }
    );
  }

  if (craftworkers.length > 0 && isFuture) {
    return (
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex">
          <StackedDate weekday={weekday} day={day} />
          <WeatherDayIcon weatherDay={weatherDay} />
        </div>
        <button
          onClick={openCraftworkerOpeningsModal}
          className=""
        >
          {craftworkers && (
            <div className="px-2 py-1">
              <AvatarGroup users={craftworkers} classes="hidden lg:flex" />
              <CountAvatar users={craftworkers} classes="hidden sm:flex lg:hidden" />
            </div>
          )}
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex w-full items-center gap-4">
        <StackedDate weekday={weekday} day={day} />
        <WeatherDayIcon weatherDay={weatherDay} />
      </div>
    );
  }
};
