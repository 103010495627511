import { Controller } from '@hotwired/stimulus';

// connects to data-controller="submit-on-change"
export default class extends Controller {
  static targets = ['form', 'input'];

  submit(_event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 125); // Adjust the delay as necessary
  }
}
