import React from 'react';
import { Avatar } from '../ui/Avatars';
import {
  ClipboardDocumentListIcon,
  ClockIcon,
  PlayCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';

const calculateShiftWidth = (hours) => {
  // return `${Math.min((Math.max(hours, 3) / 8) * 100, 100)}%`;
  return `100%`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const timeString = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return timeString.replace(/ (AM|PM)/i, '');
};

const ShiftHoursMeter = ({ totalHours, start, otherShifts }) => {
  const meterStartTime = 7; // 7 AM
  const meterHours = 12; // 12 hours
  const radius = 40;
  const circumference = 2 * Math.PI * radius; // 251.2 units
  const hourLength = circumference / meterHours; // 20.93 units per hour

  const startDate = new Date(start);
  const startHour = startDate.getHours();
  const adjustedStartHour =
    startHour >= meterStartTime
      ? startHour - meterStartTime
      : startHour + (12 - meterStartTime);

  // Calculate the dash length and offset
  const dashLength = totalHours * hourLength;
  const offset = adjustedStartHour * hourLength;

  return (
    <div>
      <svg viewBox="0 0 100 100" className="h-6 w-6" data-controller={otherShifts && 'tooltip'} data-tippy-content={otherShifts && "Multiple same day shifts"}>
        <circle
          cx="50"
          cy="50"
          r="40"
          strokeWidth={8}
          fill="none"
          className="stroke-gray-200/60"
        />
        <circle
          cx="50"
          cy="50"
          r="40"
          strokeDasharray={`${dashLength}, ${circumference - dashLength}`}
          strokeDashoffset={-offset}
          strokeWidth={8}
          fill="none"
          transform="rotate(-90 50 50)" // Rotate to make 12 o'clock at the top
          className="stroke-plum"
        />
        {otherShifts && otherShifts.map(({ start, totalHours }) => {
          const startDate = new Date(start);
          const startHour = startDate.getHours();
          const adjustedStartHour =
            startHour >= meterStartTime
              ? startHour - meterStartTime
              : startHour + (12 - meterStartTime);
          const dashLength = totalHours * hourLength;
          const offset = adjustedStartHour * hourLength;

          return (
            <circle
              key={start}
              cx="50"
              cy="50"
              r="40"
              strokeDasharray={`${dashLength}, ${circumference - dashLength}`}
              strokeDashoffset={-offset}
              strokeWidth={8}
              fill="none"
              transform="rotate(-90 50 50)" // Rotate to make 12 o'clock at the top
              className="stroke-caution"
            />
          );
        })}
      </svg>
    </div>
  );
};

const ShiftContainer = ({
  textColor = 'text-plum',
  bgColor = 'bg-white',
  width = '100%',
  classes = '',
  children,
}) => {
  return (
    <div
      className={`relative flex h-12 w-full items-center gap-2 overflow-hidden rounded-md px-2 text-sm leading-none shadow ${bgColor} ${textColor} ${classes}`}
      style={{ width }}
    >
      {children}
    </div>
  );
};

const Timesheet = ({ event }) => {
  const {
    craftworker: { familiarName },
    timesheets,
    totalTimesheetHours,
  } = event.extendedProps;
  const inProgress = timesheets.some(({ inProgress }) => inProgress);
  const approved = timesheets.some(({ approved }) => approved);
  const iconBaseClasses = 'h-6 w-6';

  const timesheetHours = (timesheets) => {
    return timesheets.map((ts) => {
      return {
        start: formatTime(ts.startedAt),
        end: formatTime(ts.endedAt),
      };
    });
  };

  const Icon = () => {
    if (inProgress) {
      return (
        <PlayCircleIcon
          className={`${iconBaseClasses} shrink-0 text-plum/60`}
        />
      );
    }

    if (approved && !inProgress) {
      return (
        <div
          className={clsx(
            'flex h-6 w-6 items-center justify-center font-base-bold text-sm',
            parseInt(totalTimesheetHours) < 7.5 && 'text-plum/60',
          )}
        >
          {totalTimesheetHours}
        </div>
      );
    } else {
      return (
        <ClipboardDocumentListIcon
          className={`${iconBaseClasses} shrink-0 text-caution`}
        />
      );
    }
  };

  return (
    <ShiftContainer
      width={calculateShiftWidth(totalTimesheetHours)}
      classes={approved && 'opacity-60'}
    >
      <a
        href={`/timesheets/${timesheets[0]?.id}?modal=true&dark_mode=true`}
        data-turbo-frame="modal"
        className="flex w-full items-center gap-2"
      >
        <Avatar user={event.extendedProps.craftworker} />
        <div className="flex-1 space-y-1">
          <span className="block truncate font-base-bold">{familiarName}</span>
          {timesheetHours(timesheets).map(({ start, end }, i) => (
            <span
              className={clsx(
                'block truncate text-xs',
                !approved && !inProgress && 'font-base-medium text-caution',
              )}
              key="i"
            >
              {i > 0 && ' & '}
              {start} - {!inProgress && end}
            </span>
          ))}
        </div>
        {Icon()}
      </a>
    </ShiftContainer>
  );
};

const ScheduledShift = ({ event }) => {
  const {
    craftworker: { familiarName },
    totalShiftHours,
    startsAt,
    endsAt,
    shiftId,
    published,
    otherSameDayShifts,
  } = event.extendedProps;

  const otherShifts = otherSameDayShifts.length > 0;

  const startsAtDate = new Date(startsAt);
  const pastShift = startsAtDate < new Date();

  const otherSameDayShiftHours = otherShifts && otherSameDayShifts.map((shift) => {
    const total_shift_hours =
      (new Date(shift.ends_at) - new Date(shift.starts_at)) / (1000 * 60 * 60);
    return {
      start: shift.starts_at,
      totalHours: total_shift_hours,
    };
  })

  const Icon = () => {
    if (startsAtDate < new Date())
      return <ClockIcon className="h-6 w-6 shrink-0 text-warning" />;

    if (!published)
      return (
        <QuestionMarkCircleIcon className="h-6 w-6 shrink-0 text-caution" />
      );

    if (published && !pastShift)
      return (
        <ShiftHoursMeter
          totalHours={totalShiftHours}
          start={event.start}
          otherShifts={otherSameDayShiftHours}
        />
      );
  };

  return (
    <a
      href={`/shifts/${shiftId}?modal=true&dark_mode=true`}
      data-turbo-frame="modal"
    >
      <ShiftContainer width={calculateShiftWidth(totalShiftHours)}>
        <Avatar user={event.extendedProps.craftworker} />
        <div className="flex-1 space-y-1">
          <span className="block truncate font-base-bold">{familiarName}</span>
          <span className="block truncate font-base-medium text-xs text-plum/60">
            {startsAtDate < new Date()
              ? 'Missed Shift'
              : `${formatTime(startsAt)} - ${formatTime(endsAt)}`}
          </span>
        </div>
        {Icon()}
      </ShiftContainer>
    </a>
  );
};

const TimeOff = ({ event }) => {
  const {
    pto,
    unPto,
    craftworker: { familiarName },
  } = event.extendedProps;
  let bgColor = pto ? 'bg-notification' : 'bg-caution';
  return (
    <ShiftContainer bgColor={bgColor} textColor="text-white">
      <Avatar user={event.extendedProps.craftworker} />
      <div className="flex-1 space-y-1">
        <span className="block truncate font-base-bold">{familiarName}</span>
        <span className="block truncate font-base-medium text-xs text-white">
          {pto && 'PTO'}
          {unPto && 'UTO'}
        </span>
      </div>
    </ShiftContainer>
  );
};

const CraftworkerOpening = ({ event }) => {
  const {
    craftworker: { familiarName },
  } = event.extendedProps;

  return (
    <ShiftContainer bgColor={`bg-caution`} textColor="text-white">
      <Avatar user={event.extendedProps.craftworker} />
      <div className="flex-1 space-y-1">
        <span className="block truncate font-base-bold">{familiarName}</span>
        <span className="block truncate font-base-medium text-xs text-white">
          {event.title}
        </span>
      </div>
    </ShiftContainer>
  );
};

const ProjectOpening = ({ event }) => {
  const {
    start,
    end,
    extendedProps: {
      pto,
      unPto,
      status,
      locationId,
      overage,
      slotNumber,
      shiftCount,
    },
    url,
    startStr,
    endStr,
  } = event;
  const timeOff = pto || unPto;

  // get today at 1am
  const date = new Date();
  date.setHours(1, 0, 0, 0);

  if (start < date) return null;

  const statusClasses =
    status == 'confirmed'
      ? 'border-plum text-plum/80'
      : 'border-plum/50 text-plum/50';

  const overageClasses = overage ? '' : 'border-blue text-blue/80';
  return (
    <a
      href={`/shifts/new?modal=true&location_id=${locationId}&starts_at=${startStr}&ends_at=${endStr}`}
      data-turbo-frame="modal"
    >
      <div
        className={clsx(
          'flex h-12 w-full items-center overflow-hidden rounded-md border border-dashed p-2 px-2 font-base-medium text-sm leading-none',
          statusClasses,
          overageClasses,
        )}
      >
        <div className="flex flex-1 flex-col items-center justify-center space-y-1">
          <span className="block truncate font-base-bold">
            {timeOff ? 'Time Off' : overage ? 'Extra Slot' : 'Open Slot'}
          </span>
          <div className="flex items-center gap-2">
            {!timeOff ? (
              <span className="block truncate text-xs">
                Click to fill{' '}
                {shiftCount !== undefined
                  ? `${slotNumber} of ${shiftCount}`
                  : null}
              </span>
            ) : (
              <span className="block truncate text-xs">Click to assign</span>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

export const Event = ({ eventInfo }) => {
  const { title, extendedProps, display } = eventInfo.event;
  const {
    id,
    locationId,
    customerId,
    location,
    totalTimesheetHours,
    totalShiftHours,
    timeOff,
    eventType,
  } = extendedProps;
  const previousShift = totalTimesheetHours > 0;

  switch (eventType) {
    case 'shift':
      if (timeOff) {
        return <TimeOff event={eventInfo.event} />;
      }

      if (previousShift) {
        return <Timesheet event={eventInfo.event} />;
      }

      return <ScheduledShift event={eventInfo.event} />;
    case 'craftworker_opening':
      return <CraftworkerOpening event={eventInfo.event} />;
    case 'project_opening':
    case 'cabinet_shop_opening':
    case 'timeoff_opening':
      return <ProjectOpening event={eventInfo.event} />;
    default:
      throw new Error(`Unknown shift type: ${eventType}`);
      break;
  }
};
