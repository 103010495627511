import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ['container'];
  connect() {}
  toggle() {
    this.containerTarget.classList.toggle('hidden');
  }

  hide(event) {
    // if (event.target.closest('.menu')) return;
    // if (this.containerTarget.classList.contains('hidden')) return;
    //
    this.containerTarget.classList.add('hidden');
  }
}
