import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener("popstate", this.handlePopState);
  }

  disconnect() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  updateURL(event) {
    const params = new URLSearchParams(window.location.search);
    const attributes = event.currentTarget.dataset;

    for (const [attr, value] of Object.entries(attributes)) {
      if (attr.startsWith('urlParam')) {
        // convert to snake case
        const paramName = attr.replace(/([A-Z])/g, "_$1").toLowerCase().replace('url_param_', '');
        // if the value is empty, remove the param
        // if the param already exists, update it
        // else, add it
        if (value === '') {
          params.delete(paramName);
        } else if (params.has(paramName)) {
          params.set(paramName, value);
        } else {
          params.append(paramName, value);
        }
      }
    }

    const newURL = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, '', newURL);
  }

  handlePopState = (event) => {
    Turbo.visit(location.href);
  }
}