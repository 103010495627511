import React from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Event } from './Event';
import { DateHeader } from './DateHeader';

const toolbarOptions = {
  dayGridWeek: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'toggleWeekendsButton staffingResourcesButton',
  },
  dayGridMonth: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'toggleWeekendsButton staffingResourcesButton',
  },
  timelineDay: {
    left: 'prev dayGridMonth,dayGridWeek,timelineDay next today',
    center: 'title',
    right: 'staffingResourcesButton',
  },
  resourceTimelineWeek: {
    left: 'prev next',
    center: 'title',
    right: 'dayGridWeek',
  },
};

const buttonsConfig = {
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  resourceTimelineWeek: 'Resources',
};

const eventUpdated = async (eventInfo) => {
  await updateProjectDates(eventInfo);
};

const eventOrder = (a, b) => {
  const eventA = a.extendedProps;
  const eventB = b.extendedProps;

  const sortOrder = [
    'cabinets',
    'specialty',
    'commercial',
    'exterior',
    'interior',
  ];

  // Find the index of the event types in the sortOrder array
  let indexA = sortOrder.indexOf(eventA.projectType);
  let indexB = sortOrder.indexOf(eventB.projectType);

  // If the projectType is not found in the sortOrder, put it at the end
  if (indexA === -1) indexA = sortOrder.length;
  if (indexB === -1) indexB = sortOrder.length;

  // First, compare by projectType
  if (indexA !== indexB) {
    return indexA - indexB;
  }

  // Then, compare by startAt (earliest first)
  if (eventA.startAt < eventB.startAt) return -1;
  if (eventA.startAt > eventB.startAt) return 1;

  // Next, compare by duration (lowest first)
  if (eventA.duration < eventB.duration) return -1;
  if (eventA.duration > eventB.duration) return 1;

  // Next, compare by allDay (true first)
  if (eventA.allDay && !eventB.allDay) return -1;
  if (!eventA.allDay && eventB.allDay) return 1;

  // Finally, compare by title (a-z)
  if (eventA.title < eventB.title) return -1;
  if (eventA.title > eventB.title) return 1;

  // If all else is equal, maintain original order
  return 0;
};

const updateProjectDates = async ({ event }) => {
  // Update the project and set it's new start and end dates.
  return await fetch(`/api/v1/events/${event.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
    },
    body: JSON.stringify({
      event: {
        startsAt: event.start,
        endsAt: event.end ?? event.start,
      },
    }),
  }).then((r) => r.json());
};

const defaultViewState = {
  view: 'dayGridWeek',
  showWeekends: false,
  currentDate: new Date().toISOString(),
};

export const ProjectsCalendar = ({ events, resources, apiKey }) => {
  const [calendarSettings, setCalendarSettings] = React.useState(() => {
    const storedSettings = JSON.parse(localStorage.getItem('project_calendar'));
    return storedSettings || defaultViewState;
  });
  const [staffingResources, setStaffingResources] = React.useState([]);
  const [weatherDays, setWeatherDays] = React.useState([]);

  const handleViewDidMount = (arg) => {
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      view: arg.view.type,
    }));
  };

  const toggleWeekends = () => {
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      showWeekends: !prevSettings.showWeekends,
    }));
  };

  const handleDatesSet = async (dateInfo, setStaffingResources) => {
    const { startStr, endStr } = dateInfo;

    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      currentDate: dateInfo.startStr,
    }));

    const result = await fetch(
      `/api/v1/calendar_events?craftworker_openings=true&start=${startStr}&end=${endStr}`,
    ).then((r) => r.json());
    setStaffingResources(result);

    const weatherResult = await fetch(
      `/api/v1/weather_days?start=${startStr}&end=${endStr}`,
    ).then((r) => r.json());

    setWeatherDays(weatherResult);
  };

  const customButtonsConfig = {
    toggleWeekendsButton: {
      text: calendarSettings.showWeekends ? 'Hide Weekends' : 'Show Weekends',
      click: toggleWeekends,
    },
    staffingResourcesButton: {
      text: 'Staffing',
      click: () => window.location.href = '/project_calendars/staffing',
    }
  };

  React.useEffect(() => {
    localStorage.setItem('project_calendar', JSON.stringify(calendarSettings));
  }, [calendarSettings]);

  return (
    <FullCalendar
      buttonText={buttonsConfig}
      customButtons={customButtonsConfig}
      datesSet={(dateInfo) => handleDatesSet(dateInfo, setStaffingResources)}
      editable={true}
      eventClassNames={'md:px-1 cursor-pointer hover:opacity-100'}
      eventContent={(eventInfo) => <Event eventInfo={eventInfo} />}
      eventDisplay="block"
      eventDrop={eventUpdated}
      eventOrder={(a, b) => eventOrder(a, b)}
      eventResizableFromStart={true}
      eventResize={eventUpdated}
      eventTextColor={'#5A4C62'}
      events={events}
      headerToolbar={toolbarOptions[calendarSettings.view]}
      height={'100%'}
      weekends={calendarSettings.showWeekends}
      initialView={calendarSettings.view}
      initialDate={calendarSettings.currentDate}
      plugins={[dayGridPlugin, resourceTimelinePlugin, interactionPlugin]}
      refetchResourcesOnNavigate={true}
      resourceGroupField={'projectType'}
      resources={resources}
      slotDuration={'06:00:00'}
      slotMaxTime={'18:00:00'}
      slotMinTime={'08:00:00'}
      schedulerLicenseKey={apiKey}
      viewClassNames={'mx-2 mb-2 border rounded-t-md rounded-b overflow-hidden'}
      viewDidMount={handleViewDidMount}
      views={{
        dayGridMonth: {
          dayHeaderFormat: { weekday: 'short' },
          titleFormat: { month: 'long' },
        },
        dayGridWeek: {
          dayHeaderFormat: {
            weekday: 'short',
            day: 'numeric',
            omitCommas: true,
          },
          dayHeaderContent: (props) => (
            <DateHeader weatherDays={weatherDays} staffingResources={staffingResources} {...props} />
          ),
          titleFormat: { month: 'long', day: 'numeric' },
        },
        timelineDay: {
          dayHeaders: false,
          titleFormat: { weekday: 'long', month: 'long', day: 'numeric' },
          slotDuration: '02:00:00',
          slotMaxTime: '17:00:00',
          slotMinTime: '08:00:00',
        },
        resourceTimelineWeek: {
          resourceAreaHeaderContent: 'Project',
          resourceAreaWidth: '20%',
          slotDuration: '02:00:00',
          slotMaxTime: '17:00:00',
          slotMinTime: '08:00:00',
          titleFormat: { month: 'long', day: 'numeric' },
        },
      }}
    />
  );
};
