import React from 'react';

export const ProgressBar = ({ hoursDisplay, ptoHours, scopedHours }) => {

  const weekHours = scopedHours;
  const overtimeHours = Math.max(0, hoursDisplay - weekHours);
  const overtimePercent = (overtimeHours / weekHours) * 100;
  const bookedPercent =
    overtimeHours > 0
      ? 100 - overtimePercent
      : (hoursDisplay / weekHours) * 100;
  const ptoPercent = ptoHours / weekHours;

  const baseClasses = 'flex justify-center text-white h-full';
  return (
    <div className="flex h-3 w-full overflow-hidden rounded bg-gray-100">
      <span
        className={`${baseClasses} bg-plum`}
        style={{ width: `${bookedPercent}%` }}
      ></span>
      <span
        className={`${baseClasses} bg-notification`}
        style={{ width: `${ptoPercent}%` }}
      ></span>
      <span
        className={`${baseClasses} bg-warning`}
        style={{ width: `${overtimePercent}%` }}
      ></span>
    </div>
  );
};