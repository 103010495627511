import { PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'

export const MoreLink = ({ args }) => {
  const { num } = args
  return (
    <div className="flex justify-center items-center font-base-medium px-4 py-2 leading-none opacity-60 rounded h-8 transition-colors hover:bg-white hover:opacity-100">
      <PlusIcon className="h-3 w-3 inline-block" /> {num} More
    </div>
  )
}
