import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="email-preview"
export default class extends Controller {
  static targets = [ "preview", "form" ]
  static values = {
    path: String
  }

  connect() {
    this.formTarget.addEventListener('change', this.previewEmail.bind(this))
  }

  previewEmail() {
    const formData = new FormData(this.formTarget)
    const queryString = new URLSearchParams(formData).toString()
    const url = `${this.pathValue}?${queryString}`
    this.previewTarget.src = url;
  }
}
