import React from 'react';

export const ProjectResourceLabel = ({ resourceContent }) => {
  const {
    id,
    title,
    extendedProps: {
      url,
      address,
      taskCount,
      projectType,
      completedTaskCount,
      hoursCommitted, // sum of hours * committed crew for each event
      hoursWorked, // sum of timesheet hours
      hoursStaffed, // sum of shift hours
      productionLead,
      totalEventDays,
      resourceType,
      account: { name },
    },
  } = resourceContent.resource;

  const indicatorColor = projectType ? `bg-${projectType}` : `bg-gray-200`;

  return (
    <div className="flex w-full gap-2">
      <div className={`h-10 w-1 rounded ${indicatorColor}`}></div>
      <div>
        <a
          href={url}
          className="flex flex-col gap-2 md:flex-row md:items-center"
        >
          <span className="font-base-bold">{title}</span>
        </a>

        {resourceType === 'Project' && (
          <div className="text-sm text-gray-600">
            <span className="font-base-medium">
              {}
              {totalEventDays}
            </span>{' '}
            days
          </div>
        )}
      </div>
    </div>
  );
};
