import React from 'react';

const Submark = ({ className }: {className: string}) => (
  <svg
    viewBox="0 0 270 270"
    fill="currentColor"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M270 194.477H77.985V129.841L151.087 87.6211L270 153.473V65.9064L150.975 0L0 87.1652V270H270V194.477Z"
    />
  </svg>
);

export default Submark;
