// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import Notification from 'stimulus-notification'

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('modal', Modal)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register('notification', Notification)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import AccordionController from '@kanety/stimulus-accordion';
application.register('accordion', AccordionController)

import TabsController from './tabs_controller';
application.register('tabs', TabsController);

import DropdownController from './dropdown_controller'
application.register('dropdown', DropdownController)
