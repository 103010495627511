import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="save-draft"
//
// This controller should be used on form input elements like input and textareas.
//
// Note, the key should be unique to the page or context. E.g. if you have a conversation
// you'd want the key to include the string "conversation" and some unique ID for the specific
// conversation. An ID is not enough since that will conflict between conversations and comments for example.
//
// E.g.:
//
// <textarea data-controller="save-draft" data-save-draft-key-value="conversation_draft_123"></textarea>
export default class extends Controller {
  static values = {
    key: String,
  }

  connect() {
    this.loadDraft();

    // When the input changes, save the draft.
    if (this.element.editor) {
      this.element.addEventListener("trix-change", this.save.bind(this));
    } else {
      this.element.addEventListener("input", this.save.bind(this));
    }

    // Clears the draft when the form is submitted.
    if (this.element.form) {
      this.element.form.addEventListener("turbo:submit-end", this.clearDraft.bind(this));
    }
  }

  disconnect() {
    this.element.removeEventListener("input", this.save.bind(this));
    if (this.element.form) {
      this.element.form.removeEventListener("turbo:submit-end", this.clearDraft.bind(this));
      this.element.form.removeEventListener("submit", this.clearDraft.bind(this));
    }
  }

  loadDraft() {
    const draftData = localStorage.getItem(this.keyValue);
    if (draftData) {
      const draft = JSON.parse(draftData);
      if (draft) {
        this.element.value = draft.body;

        // TODO: Load attachments??
        // this.loadAttachments(draft.attachments);
      }
    }
  }

  save() {
    const draft = {
      body: this.element.value,
      // TODO: Save attachments??
      // attachments: this.collectAttachmentInfo(),
    };
    localStorage.setItem(this.keyValue, JSON.stringify(draft));
  }

  clearDraft() {
    localStorage.removeItem(this.keyValue);
    setTimeout(() => {
      this.element.value = "";
    }, 10);
  }
}
