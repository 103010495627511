
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="done-checkbox"
export default class extends Controller {
  static targets = ["field", "checkbox"]

  toggleDone() {
    if (this.checkboxTarget.checked) {
      this.fieldTarget.value = true
    } else {
      this.fieldTarget.value = false
    }
    Turbo.navigator.submitForm(this.element)
  }
}
