import Select, { Option, SelectOption } from './Select';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';

export type Filter = {
  name: string;
  label: string;
  options: Option[];
};

type FilterPillProps = {
  filter: Filter;
  initialValue: string | null;
  updateFilterOption: (filter: string, option: Option) => void;
  removeFilter: (filter: string) => void;
};

export const FilterPill = ({
  filter,
  initialValue,
  updateFilterOption,
  removeFilter,
}: FilterPillProps) => {
  const pillRef = React.useRef<HTMLDivElement>(null);
  const initialOption = filter.options.find(
    (option) => option.value == initialValue,
    ) || {
      label: filter.label,
      name: "",
      value: "",
    };
  const [selectedOption, setSelectedOption] = React.useState(initialOption);
  const [freeSpaceRight, setFreeSpaceRight] = React.useState(0);
  const [freeSpaceBelow, setFreeSpaceBelow] = React.useState(0);

  React.useEffect(() => {
    if (!pillRef.current) return;
    // get the available space for the dropdown menu below
    const pillRect = pillRef.current.getBoundingClientRect();
    const pillHeight = pillRect.height;
    const pillBottom = pillRect.bottom;
    const windowHeight = window.innerHeight;

    setFreeSpaceBelow(windowHeight - pillBottom + pillHeight);
  }, [pillRef.current]);

  React.useEffect(() => {
    if (!pillRef.current) return;
    // get the available space for the dropdown menu to the right and bottom
    const pillRect = pillRef.current.getBoundingClientRect();
    const pillWidth = pillRect.width;
    const pillRight = pillRect.right;
    const pillBottom = pillRect.bottom;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const gapBetweenPillAndMenu = 8;

    setFreeSpaceRight(windowWidth - pillRight + pillWidth);
    setFreeSpaceBelow(windowHeight - pillBottom - gapBetweenPillAndMenu);
  }, [pillRef.current]);

  return (
    <div
      ref={pillRef}
      className="group hover:bg-gray-50 bg-gray-50 flex h-6 items-center rounded-full pl-1 pr-px text-xs focus-within:ring-2 focus-within:ring-offset-1"
    >
      <Select
        key={filter.name}
        defaultValue={initialOption}
        onChange={(option) => {
          setSelectedOption(option);
          updateFilterOption(filter.name, option);
        }}
        value={selectedOption}
        title={filter.label}
        freeSpaceRight={freeSpaceRight}
        freeSpaceBelow={freeSpaceBelow}
      >
        {filter?.options.map((option, idx) => {
          return <SelectOption key={option.value} option={option} />;
        })}
      </Select>
      <button
        type="button"
        className="flex h-[20px] w-[20px] items-center justify-center rounded-full text-gray-300 hover:bg-red/50 hover:text-white"
        onClick={() => removeFilter(filter.name)}
      >
        <XMarkIcon className="h-4 w-4" />
      </button>
    </div>
  );
};
