import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address-complete"
export default class extends Controller {
  static targets = [
    "field",
    "line1",
    "city",
    "state",
    "zip",
    "lat",
    "lng",
    "place_id",
    "advanced"
  ]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() {
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      types: ['address'],
      componentRestrictions: { country: "us" },
      fields: ['address_components', 'geometry', 'icon', 'name', 'place_id', 'formatted_address']
    })
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()

    const streetNumber = place.address_components.find(c => c.types.includes("street_number"));
    const streetName = place.address_components.find(c => c.types.includes("route"));
    const line1 = `${streetNumber?.long_name || ''} ${streetName?.short_name || ''}`.trim();
    const city = place.address_components.find(c => c.types.includes("locality")).long_name;
    const zip = place.address_components.find(c => c.types.includes("postal_code")).long_name;
    const state = place.address_components.find(c => c.types.includes("administrative_area_level_1")).short_name;

    if(this.hasLine1Target) {
      this.line1Target.value = line1;
      this.cityTarget.value = city;
      this.stateTarget.value = state;
      this.zipTarget.value = zip;
    }
    if(this.hasLatTarget) {
      this.latTarget.value = place.geometry.location.lat();
      this.lngTarget.value = place.geometry.location.lng();
    }
    this.place_idTarget.value = place.place_id;

    // New: Update the raw_address field with formatted_address
    if (place.formatted_address) {
      this.fieldTarget.value = place.formatted_address;
    }
  }

  toggleAdvanced() {
    this.advancedTarget.classList.toggle("hidden")
  }

  keydown(e) {
    if (e.key == "Enter") {
      e.preventDefault()
    }
  }
}
