// app/javascript/controllers/search_form_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, 1500); // 1.5 seconds
  }
}
