import React from "react";
import { ChartPieIcon, ClockIcon, ReceiptPercentIcon } from "@heroicons/react/24/outline";
import { StackedDate } from "../ui/StackedDate";

export const SlotLabel = ({currentEvents, currentResources, date, text }) => {
  const day = text.split(" ")[0];
  const weekday = text.split(" ")[1];

  const beginningOfDay = new Date(date).setHours(0, 0, 0, 0);
  const endOfDay = new Date(date).setHours(23, 59, 59, 999);

  const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);

  const activeCraftworkerCount = currentResources.filter((resource) => {
    const joinedDate = Date.parse(resource.extendedProps.joinedDate);
    const leftDate =
      resource.extendedProps.discardedAt &&
      Date.parse(resource.extendedProps.discardedAt);

    // If the date is during the Craftworker's tenure, count them as active.
    return (
      joinedDate < date &&
      (!resource.extendedProps.discardedAt || leftDate > date)
    );
  }).length;

  const possibleHours = activeCraftworkerCount * 8;
  const workedHours = sum(
    currentEvents
      .filter((evt) => {
        return evt.start >= beginningOfDay && evt.end <= endOfDay;
      })
      .map((evt) => evt.extendedProps.totalTimesheetHours),
  ).toFixed(1);
  const utilization = ((workedHours / possibleHours) * 100).toFixed(1);

  return (
    <div className="flex w-full justify-between">
      <StackedDate weekday={weekday} day={day} />
      <div className="flex flex-col items-end gap-1 text-xs font-base-medium">
        <div className="flex items-center gap-1">
          {workedHours} / {possibleHours}
          <ClockIcon className="h-4 w-4 shrink-0 text-gray-500" />
        </div>
        <div className="flex items-center gap-1">
          {utilization}%
          <ReceiptPercentIcon className="h-4 w-4 shrink-0 text-gray-500" />
        </div>
      </div>
    </div>
  );
};
