// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js';
// import 'tippy.js/themes/light.css';

export default class extends Controller {
  static values = {
    content: String,
  };

  connect() {
    const defaultOptions = {
      allowHTML: true,
      arrow: false,
      delay: [750, null],
      offset: [0, 2],
      placement: 'bottom',
      // theme: 'light',
      appendTo: () => document.body,
    };

    let options = {};

    // load up the options object with any data attributes that start with 'tippy-'
    for (let i = 0; i < this.element.attributes.length; i++) {
      let attr = this.element.attributes[i];
      if (attr && attr.name.startsWith('tippy-')) {
        let key = attr.name.replace('tippy-', '');
        let value = attr.value;
        if (key && value) {
          options[key] = value;
        }
      }
    }

    // combine default options and specified options
    options = Object.assign({}, defaultOptions, options);

    this.tippy = tippy(this.element, options);
  }

  disconnect() {
    this.tippy.destroy();
  }
}
