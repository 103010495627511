import React from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ResourceLabel } from './ResourceLabel';
import { GroupLabel } from './GroupLabel';
import { Event } from './Event';
import { SlotLabel } from './SlotLabel';
import { ResourceHeader } from './ResourceHeader';

const headerConfig = {
  left: 'prev resourceTimelineMonth,resourceTimelineWeek next',
  center: 'title',
  right: 'toggleWeekendsButton today',
};

const buttonsConfig = {
  toggleWeekendsButton: 'Show Weekends',
  today: 'Current',
  resourceTimelineMonth: 'Month',
  resourceTimelineWeek: 'Week',
};

const countWorkdays = (start, end) => {
  let count = 0;
  let currentDate = new Date(start);
  while (currentDate <= end) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      // Monday is 1 and Friday is 5
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return count;
};

const checkIfCurrentWeek = (view) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date

  const startOfWeek = new Date(view.activeStart);
  const endOfWeek = new Date(view.activeEnd);
  endOfWeek.setHours(23, 59, 59, 999); // Set end of day for comparison

  return today >= startOfWeek && today <= endOfWeek;
};

const defaultViewState = {
  view: 'resourceTimelineWeek',
  showWeekends: false,
  currentDate: new Date().toISOString(),
};

export const ShiftsCalendar = ({ events, resources, apiKey }) => {
  const [calendarSettings, setCalendarSettings] = React.useState(() => {
    const storedSettings = JSON.parse(localStorage.getItem('shift_calendar'));
    return storedSettings || defaultViewState;
  });

  const [showWeekends, setShowWeekends] = React.useState(false);
  const [currentEvents, setCurrentEvents] = React.useState([]);
  const [currentResources, setCurrentResources] = React.useState([]);
  const [isCurrentWeek, setIsCurrentWeek] = React.useState(false);
  const [scopedHours, setScopedHours] = React.useState(40);

  const handleDateSet = (info) => {
    setIsCurrentWeek(checkIfCurrentWeek(info.view));
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      currentDate: info.startStr,
    }));

    if (info.view.type === 'dayGridMonth') {
      const workdaysCount = countWorkdays(
        info.view.activeStart,
        info.view.activeEnd,
      );
      setScopedHours(workdaysCount * 8);
    } else {
      setScopedHours(40);
    }
  };

  const handleEvents = (events) => {
    setCurrentEvents(events);
  };

  const handleResources = (resources) => {
    setCurrentResources(resources);
  };

  const handleViewDidMount = (arg) => {
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      view: arg.view.type,
    }));
  };

  const toggleWeekends = () => {
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      showWeekends: !prevSettings.showWeekends,
    }));
  };

  const customButtons = {
    toggleWeekendsButton: {
      text: calendarSettings.showWeekends ? 'Hide Weekends' : 'Show Weekends',
      click: toggleWeekends,
    },
  };

  React.useEffect(() => {
    localStorage.setItem('shift_calendar', JSON.stringify(calendarSettings));
  }, [calendarSettings]);

  return (
    <FullCalendar
      buttonText={buttonsConfig}
      customButtons={customButtons}
      datesSet={handleDateSet}
      editable={false}
      eventClassNames={'px-1 cursor-pointer hover:opacity-100'}
      eventContent={(eventInfo) => <Event eventInfo={eventInfo} />}
      eventResizableFromStart={false}
      eventTextColor={'#5A4C62'}
      events={events}
      eventsSet={handleEvents}
      headerToolbar={headerConfig}
      height={'100%'}
      initialView={calendarSettings.view}
      initialDate={calendarSettings.currentDate}
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      refetchResourcesOnNavigate={true}
      resources={resources}
      resourceAreaWidth={'30%'}
      resourceAreaHeaderClassNames={''}
      resourceAreaHeaderContent={(args) => (
        <ResourceHeader args={args} currentEvents={currentEvents} />
      )}
      resourceLabelContent={(args) => (
        <ResourceLabel
          args={args}
          isCurrentWeek={isCurrentWeek}
          scopedHours={scopedHours}
        />
      )}
      resourceGroupField={'team'}
      resourceGroupLabelContent={(args) => <GroupLabel args={args} />}
      resourceGroupLaneClassNames={'bg-plum'}
      resourcesSet={handleResources}
      slotMinTime={'07:00:00'}
      slotMaxTime={'18:00:00'}
      slotLabelContent={(props) => (
        <SlotLabel
          currentResources={currentResources}
          currentEvents={currentEvents}
          {...props}
        />
      )}
      slotLabelInterval={{ days: 1 }}
      slotLabelFormat={{
        weekday: 'short',
        day: 'numeric',
        omitCommas: true,
      }}
      schedulerLicenseKey={apiKey}
      titleFormat={{ month: 'long', day: 'numeric' }}
      viewDidMount={handleViewDidMount}
      viewClassNames={'mx-2 mb-2 border rounded-t-md rounded-b overflow-hidden'}
      weekends={calendarSettings.showWeekends}
    />
  );
};
