import { Controller } from '@hotwired/stimulus';
import { Sortable } from '@shopify/draggable';

// Connects to data-controller="sortable"
export default class extends Controller {
  static classes = ['draggable', 'handle'];
  static targets = ['item', 'input'];
  static values = { url: String };

  connect() {
    this.sortable = new Sortable(this.element, {
      draggable: this.draggableClass,
      handle: this.handleClass,
    });

    this.sortable.on('drag:start', (e) => {
      e.data.source.classList.add('shadow', "border-plum/40", "border");
    })

    if (this.urlValue !== undefined) {
      this.sortable.on('drag:stopped', async (e) => {
        await this.dragStopped();
      });
    }
  }

  async dragStopped() {
    if (this.urlValue !== undefined && this.urlValue !== '') {
      await fetch(this.urlValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          order: this.itemTargets.reduce((obj, item, i) => {
            obj[item.dataset.id] = i;
            return obj;
          }, {}),
        }),
      });
    }

    if (this.inputTargets.length > 0) {
      this.inputTargets.forEach((input, i) => {
        input.value = i;
      });
    }
  }
}
