import React from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/classNames';

type SelectProps = {
  defaultValue?: Option;
  onChange: (value: Option) => void;
  children: React.ReactNode;
  value: Option | undefined;
  title: string;
  freeSpaceRight: number;
  freeSpaceBelow: number;
};

const Select = ({ defaultValue, onChange, children, value, title, freeSpaceRight, freeSpaceBelow }: SelectProps) => {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuLeft, setMenuLeft] = React.useState(0);
  const [maxMenuHeight, setMaxMenuHeight] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (!menuRef.current) return;
    const menuRect = menuRef.current.getBoundingClientRect();
    const menuHeight = menuRect.height;
    const menuWidth = menuRect.width;

    // set the max menu height based on available space below pill
    if (menuHeight > freeSpaceBelow) {
      setMaxMenuHeight(freeSpaceBelow - 16);
    } else {
      setMaxMenuHeight(null);
    }
    //  set left offset of menu relative to pill based on available space to the right of pill
    if (menuWidth > freeSpaceRight) {
      const leftOffset = menuHeight > freeSpaceBelow ? 48 : 16;
      setMenuLeft((menuWidth - freeSpaceRight + leftOffset) * -1);
    } else {
      setMenuLeft(-4);
    }

  }, [menuRef.current]);

  return (
    <Listbox defaultValue={defaultValue} onChange={onChange} value={value}>
      {({ open, ...rest }) => (
        <div className="relative">
          <Listbox.Button className="flex h-full w-full pl-1 pr-0.5 items-center gap-1 whitespace-nowrap rounded-full font-base-medium text-gray-600 group-hover:text-plum focus:outline-none focus:ring-0 text-xs leading-0">
            {(selectedItem) => {
              const selectedOption = selectedItem?.value;
              return <>{selectedOption?.label || 'Select an option'}</>;
            }}
          </Listbox.Button>
          <Listbox.Options static ref={menuRef} style={{ left: menuLeft, maxHeight: maxMenuHeight || "none" }} className={classNames(
            !open ? 'invisible' : 'visible',
            "absolute z-10 top-[calc(100%_+_12px)] w-max overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          )}>
            <span className="block py-1 px-2 text-gray-400 font-base-medium border-b mx-2 mb-1">{title}</span>
            {children}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export type Option = {
  label: string;
  name: string;
  value: string | number;
};

type SelectOptionProps = {
  option: Option;
  children?: React.ReactNode;
};

export const SelectOption = ({ option, children }: SelectOptionProps) => {
  return (
    <Listbox.Option
      className={({ active }) =>
        classNames(
          active ? 'bg-gray-25' : '',
          'relative cursor-default select-none py-2 px-3 mx-1 rounded min-w-[150px]',
        )
      }
      key={option.value}
      value={option}
    >
      {({ selected, active }) => (
        <div className="flex items-center justify-center gap-1">
          <span
            className={classNames(
              selected ? 'font-base-medium' : 'font-base-regular',
              'w-full block truncate',
            )}
          >
            {option.label}
          </span>


          { selected && <CheckIcon className="h-4 w-4 shrink-0" aria-hidden="true" /> }
        </div>
      )}
    </Listbox.Option>
  );
};

export default Select;
