import React from 'react';
import { NoSymbolIcon, ClockIcon } from "@heroicons/react/24/outline";

export const ProjectResourceHeader = ({ resourceContent }) => {
  return (
    <div className="flex flex-col gap-1 text-xs font-base-medium">
      <div className="flex items-center gap-1">
        Projects
      </div>
    </div>
  );
};
