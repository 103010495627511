import React, { useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { ProjectResourceLabel } from './ProjectResourceLabel';
import { GroupLabel } from './GroupLabel';
import { Event } from './Event';
import { DayHeader } from './DayHeader';
import { ProjectResourceHeader } from './ProjectResourceHeader';
import { MoreLink } from './MoreLink';
import consumer from '../../channels/consumer';

type ToolbarOptions = {
  [key: string]: {
    left: string;
    center: string;
    right: string;
  };
};

const CalendarSettingsViewOptions = {
  ResourceTimelineDay: 'resourceTimelineDay',
  ResourceTimelineWeek: 'resourceTimelineWeek',
} as const;

const toolbarOptions: ToolbarOptions = {
  resourceTimelineDay: {
    left: 'prev today next',
    center: 'title',
    right: 'projectCalendarButton',
  },
  resourceTimelineWeek: {
    left: 'today toggleWeekendsButton',
    center: 'prev title next',
    right: 'projectCalendarButton',
  },
};

const buttonsConfig = {
  toggleWeekendsButton: 'Show Weekends',
  today: 'Today',
  resourceTimelineWeek: 'Week',
  resourceTimelineDay: 'Day',
};

const eventOrder = (a: any, b: any) => {
  const eventA = a.extendedProps;
  const eventB = b.extendedProps;

  const projectTypeOrder = [
    'cabinets',
    'specialty',
    'commercial',
    'exterior',
    'interior',
  ];

  const eventTypeOrder = ['shift', 'project_opening', 'craftworker_opening'];

  // Find the index of the event types in the projectTypeOrder array
  let indexA = projectTypeOrder.indexOf(eventA.projectType);
  let indexB = projectTypeOrder.indexOf(eventB.projectType);

  // If the projectType is not found in the projectTypeOrder, put it at the end
  if (indexA === -1) indexA = projectTypeOrder.length;
  if (indexB === -1) indexB = projectTypeOrder.length;

  // First, compare by projectType
  if (indexA !== indexB) {
    return indexA - indexB;
  }

  // Find the index of the event types in the eventTypeOrder array
  indexA = eventTypeOrder.indexOf(eventA.eventType);
  indexB = eventTypeOrder.indexOf(eventB.eventType);

  // If the projectType is not found in the eventTypeOrder, put it at the end
  if (indexA === -1) indexA = eventTypeOrder.length;
  if (indexB === -1) indexB = eventTypeOrder.length;

  // First, compare by projectType
  if (indexA !== indexB) {
    return indexA - indexB;
  }

  // Then, compare by startAt (earliest first)
  if (eventA.startAt < eventB.startAt) return -1;
  if (eventA.startAt > eventB.startAt) return 1;

  // Next, compare by duration (lowest first)
  if (eventA.duration < eventB.duration) return -1;
  if (eventA.duration > eventB.duration) return 1;

  // Next, compare by allDay (true first)
  if (eventA.allDay && !eventB.allDay) return -1;
  if (!eventA.allDay && eventB.allDay) return 1;

  // Finally, compare by title (a-z)
  if (eventA.title < eventB.title) return -1;
  if (eventA.title > eventB.title) return 1;

  // If all else is equal, maintain original order
  return 0;
};

type ViewState = {
  view: string;
  showWeekends: boolean;
  currentDate: string;
};

const defaultViewState = {
  view: 'resourceTimelineWeek',
  showWeekends: false,
  currentDate: new Date().toISOString(),
};

type StaffingCalendarProps = {
  events: any[];
  resources: any[];
  apiKey: string;
};

export const StaffingCalendar = ({
  events,
  resources,
  apiKey,
}: StaffingCalendarProps) => {
  const calendarRef = useRef(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const [calendarSettings, setCalendarSettings] = React.useState<ViewState>(
    () => {
      const storedSettings = localStorage.getItem('staffing_calendar');
      if (storedSettings) {
        return JSON.parse(storedSettings);
      }
      return defaultViewState;
    },
  );
  const [weatherDays, setWeatherDays] = React.useState([]);
  const [craftworkerOpenings, setCraftworkerOpenings] = React.useState([]);
  const [dailyPainterCount, setDailyPainterCount] = React.useState([]);
  const handleViewDidMount = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  };

  const toggleWeekends = () => {
    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      showWeekends: !prevSettings.showWeekends,
    }));
  };

  const handleDatesSet = async (dateInfo: any) => {
    const { startStr, endStr } = dateInfo;

    setCalendarSettings((prevSettings) => ({
      ...prevSettings,
      view: dateInfo.view.type,
      currentDate: dateInfo.startStr,
    }));

    const resultResponse = await fetch(
      `/api/v1/calendar_events?craftworker_openings=true&start=${startStr}&end=${endStr}`,
    );
    const result = await resultResponse.json();
    setCraftworkerOpenings(result);

    const weatherResponse = await fetch(
      `/api/v1/weather_days?start=${startStr}&end=${endStr}`,
    );
    const weatherResult = await weatherResponse.json();
    setWeatherDays(weatherResult);

    const dailyPainterCountResponse = await fetch(
      `/api/v1/craftworkers/painter_count?start=${startStr}&end=${endStr}`,
    );
    const dailyPainterCountResult = await dailyPainterCountResponse.json();
    setDailyPainterCount(dailyPainterCountResult);
  };

  const handleEventDidMount = (arg) => {
    const element = arg.el;
    // remove default element href applied by FullCalendar
    element.removeAttribute('href');
  };

  const customButtonsConfig = {
    toggleWeekendsButton: {
      text: calendarSettings.showWeekends ? 'Hide Weekends' : 'Show Weekends',
      click: toggleWeekends,
    },
    projectCalendarButton: {
      text: 'Projects',
      click: () => (window.location.href = '/project_calendars'),
    },
  };

  React.useEffect(() => {
    localStorage.setItem('staffing_calendar', JSON.stringify(calendarSettings));
  }, [calendarSettings]);

  React.useEffect(() => {
    if (!calendarRef.current) return;
    const calendarApi = calendarRef.current.getApi();

    const handleResize = () => {
      if (window.innerWidth < 768 && !isMobile) {
        calendarSettings.view !==
          CalendarSettingsViewOptions.ResourceTimelineDay &&
          calendarApi.changeView(
            CalendarSettingsViewOptions.ResourceTimelineDay,
          );
        setIsMobile(true);
      } else if (window.innerWidth >= 768 && isMobile) {
        calendarApi.changeView(
          CalendarSettingsViewOptions.ResourceTimelineWeek,
        );
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // When a new calendar event is created like a shift or timesheet.
  React.useEffect(() => {
    consumer.subscriptions.create('CalendarEventsChannel', {
      received(rawEvent) {
        const { action, event } = JSON.parse(rawEvent);

        if (calendarRef.current) {
          const calendarApi = calendarRef.current.getApi();

          // If the event is already in the calendar, remove is so we don't duplicate it.
          if (calendarApi.getEventById(event.id)) {
            calendarApi.getEventById(event.id).remove();
          }

          switch (action) {
            case 'upsert':
              // When we upsert, we add the updated event back to the calendar.
              // This works for both update and create actions.
              calendarApi.addEvent(event);
              break;
            case 'destroy':
              // When we destroy, we remove the event from the calendar, but don't add it back
              // so this is effectively a no-op.
              break;
            default:
              break;
          }
        }
      },
    });
  }, [consumer]);

  const handleEventDrop = async (eventDropInfo) => {
    console.log(eventDropInfo);

    // Only do this if it's a shift, otherwise return early.
    if (eventDropInfo.event.extendedProps.eventType !== 'shift') {
      return;
    }

    // Try to update the shift,
    const shift = {
      starts_at: eventDropInfo.event.start,
      ends_at: eventDropInfo.event.end,
      shift_id: eventDropInfo.event.id,
      location_id: eventDropInfo.newResource.extendedProps.locationId,
      project_id: eventDropInfo.newResource.id,
      craftworker_id: eventDropInfo.event.extendedProps.craftworker.id,
    };

    try {
      await fetch(`/shifts/${eventDropInfo.event.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
        },
        body: JSON.stringify({ shift }),
      });
    } catch (error) {
      // If it fails, revert the event back to the original resource:
      console.error('Failed to update shift', error);
    }
  };

  return (
    <FullCalendar
      ref={calendarRef}
      buttonText={buttonsConfig}
      customButtons={customButtonsConfig}
      datesSet={(dateInfo) => handleDatesSet(dateInfo)}
      droppable={true}
      editable={false}
      eventResourceEditable={true}
      eventClassNames={'px-1 cursor-pointer hover:opacity-100'}
      eventContent={(eventInfo) => <Event eventInfo={eventInfo} />}
      eventDisplay="block"
      eventDrop={handleEventDrop}
      eventOrder={(a, b) => eventOrder(a, b)}
      eventOverlap={true}
      eventTextColor={'#5A4C62'}
      eventDidMount={(arg) => handleEventDidMount(arg)}
      events={events}
      eventMaxStack={4}
      expandRows={true}
      headerToolbar={toolbarOptions[calendarSettings.view]}
      height={'100%'}
      initialDate={calendarSettings.currentDate}
      initialView={CalendarSettingsViewOptions.ResourceTimelineWeek}
      moreLinkClassNames={'p-1 hover:opacity-100'}
      moreLinkContent={(arg) => <MoreLink args={arg} />}
      plugins={[resourceTimelinePlugin, interactionPlugin]}
      refetchResourcesOnNavigate={true}
      resources={resources}
      resourceAreaWidth={isMobile ? '50%' : '20%'}
      resourceGroupField={'projectType'}
      resourceGroupLabelContent={(resourceContent) => (
        <GroupLabel args={resourceContent} />
      )}
      resourceAreaHeaderContent={(resourceContent) => (
        <ProjectResourceHeader resourceContent={resourceContent} />
      )}
      resourceLabelContent={(resourceContent) => (
        <ProjectResourceLabel resourceContent={resourceContent} />
      )}
      resourceGroupLaneClassNames={'bg-plum'}
      resourceOrder={'totalEventDays'}
      slotDuration={{ days: 1 }}
      slotLabelContent={(props) => (
        <DayHeader
          {...props}
          weatherDays={weatherDays}
          craftworkerOpenings={craftworkerOpenings}
          painterCount={dailyPainterCount}
        />
      )}
      slotLabelInterval={{ days: 1 }}
      slotLabelFormat={{
        weekday: 'short',
        day: 'numeric',
        omitCommas: true,
      }}
      schedulerLicenseKey={apiKey}
      titleFormat={{ month: 'long', day: 'numeric' }}
      viewClassNames={'mx-2 mb-2 border rounded-t-md rounded-b overflow-hidden'}
      viewDidMount={handleViewDidMount}
      views={{
        resourceTimelineDay: {
          resourceAreaHeaderContent: 'Project',
          dayHeaders: false,
          titleFormat: { weekday: 'long', month: 'long', day: 'numeric' },
        },
        resourceTimelineWeek: {
          resourceAreaHeaderContent: 'Project',
          titleFormat: { month: 'long', day: 'numeric' },
        },
      }}
      weekends={calendarSettings.showWeekends}
    />
  );
};
