import React, { useMemo, useState, useEffect } from 'react';
import type { FillLayer, SymbolLayer } from 'react-map-gl';
import Map, { Layer, Marker, Popup, Source } from 'react-map-gl';

import { ShoppingCartIcon, StarIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { BuildingStorefrontIcon } from '@heroicons/react/16/solid';
import ProjectTypeBadge from './ProjectTypeBadge';

import config from '../../../tailwind.config';
import Submark from './ui/Submark';

const COLORS = config?.theme?.extend?.colors;

const dataLayer: FillLayer = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': '#32213B',
    'fill-opacity': 0.5,
  },
  source: 'my-data',
};

const labelLayer: SymbolLayer = {
  id: 'zip-label',
  type: 'symbol',
  layout: {
    'text-field': ['get', 'ZCTA5CE10'],
    'text-size': 12,
  },
  paint: {
    'text-color': '#FFFFFF',
  },
  source: 'my-data',
};

type Address = {
  id: bigint;
  address_type: number;
  addressable_type: string;
  city: string;
  country: string;
  discarded_at: Date;
  lat: number;
  line1: string;
  line2: string;
  lng: number;
  postal_code: string;
  state: string;
  created_at: Date;
  updated_at: Date;
  addressable_id: bigint;
  place_id: string;
};

type Account = {
  name: string;
  id: string;
};

type Project = {
  id: string;
  projectType: string;
  account: Account;
  title: string;
  url: string;
};

type Vendor = {
  id: string;
  companyName: string;
  contactName: string;
  email: string;
  formattedPhone: string;
  website: string;
  preferred: boolean;
  notes: string;
};

type Location = {
  id: string;
  displayName: string;
  locationType: 'cabinet_shop' | 'headquarters';
  address: Address;
};

type ProjectLocation = {
  id: string;
  address: Address;
  displayName: string;
  locationType: 'customer';
  project: Project;
};

type VendorLocation = {
  id: string;
  displayName: string;
  contactName: string;
  formattedPhone: string;
  locationType: 'vendor_location';
  website: string;
  vendor: Vendor;
  address: Address;
};

type ServiceAreaMapProps = {
  style?: React.CSSProperties;
  zoom?: number;
  start: string;
  end: string;
  mapboxToken: string;
};

const LocationDetailAddress: React.FC<{ address: Address }> = ({ address }) => {
  return (
    <div>
      <h5>Address</h5>
      <span className="capitalize">{address.line1}</span>
      {address.line2 && (
        <span className="capitalize">{address.line2}</span>
      )}
      <span className="capitalize">
        {', '}
        {address.city}, {address.state}{' '}
        {address.postal_code}
      </span>
    </div>
  )
};

const ProjectLocationDetail: React.FC<{ selectedLocation: ProjectLocation }> = ({ selectedLocation }) => {
  const { project, address } = selectedLocation;

  return (
    <div className="rounded-lg bg-plum/90 p-1 text-white shadow-md backdrop-blur-sm">
      <div className="py-3 pl-3 pr-5">
        <div className="mb-4">
          <h2 className="font-base-bold">{project.account.name}</h2>
          <p>{project.title}</p>
        </div>
        <div className="mb-2">
          <h5>Project Type</h5>
          <span className="capitalize">{project.projectType}</span>
        </div>

        <LocationDetailAddress address={address} />
      </div>
      <a
        href={project.url}
        className="flex h-8 w-full items-center justify-center rounded bg-white/10 px-2 font-base-medium text-white hover:bg-white hover:text-plum focus:outline-none"
      >
        View Project
      </a>
    </div>
  );
};

const VendorLocationDetail: React.FC<{ selectedLocation: VendorLocation }> = ({ selectedLocation }) => {
  const {
    vendor,
    address,
    contactName,
    formattedPhone,
    website
  } = selectedLocation;

  return (
    <div className="rounded-lg bg-plum/90 p-1 text-white shadow-md backdrop-blur-sm">
      <div className="py-3 pl-3 pr-5">
        <div className="mb-4">
          <h2 className="font-base-bold">{selectedLocation.displayName}</h2>
          <p>{vendor.companyName}</p>
        </div>
        <div className="mb-2">
          <h5>Contact</h5>
          <span className="">{contactName}</span>
          <span className="">{formattedPhone}</span>
        </div>

        <LocationDetailAddress address={address} />
      </div>
      <a
        href={website}
        className="flex h-8 w-full items-center justify-center rounded bg-white/10 px-2 font-base-medium text-white hover:bg-white hover:text-plum focus:outline-none"
        target="_blank"
      >
        Website
      </a>
    </div>
  );
};

const CabinetShopLocationDetail: React.FC<{ selectedLocation: Location }> = ({ selectedLocation }) => {
  const {
   address,
   displayName,
  } = selectedLocation;

  return (
    <div className="rounded-lg bg-plum/90 p-1 text-white shadow-md backdrop-blur-sm">
      <div className="py-3 pl-3 pr-5">
        <div className="mb-4">
          <h2 className="font-base-bold">{displayName}</h2>
        </div>
        <LocationDetailAddress address={address} />
      </div>
    </div>
  );
}

const HeadquartersLocationDetail: React.FC<{ selectedLocation: Location }> = ({ selectedLocation }) => {
  return (
    <div className="rounded-lg bg-plum/90 p-1 text-white shadow-md backdrop-blur-sm">
      <div className="py-3 pl-3 pr-5">
        <div className="mb-4">
          <h2 className="font-base-bold">{selectedLocation.displayName}</h2>
        </div>
        <LocationDetailAddress address={selectedLocation.address} />
      </div>
    </div>
  );
}

const LocationDetail: React.FC<{ selectedLocation: CraftworkLocation }> = ({ selectedLocation }) => {
  switch (selectedLocation.locationType) {
  case 'customer':
    return <ProjectLocationDetail selectedLocation={selectedLocation as ProjectLocation} />;
  case 'vendor_location':
    return <VendorLocationDetail selectedLocation={selectedLocation as VendorLocation} />;
  case 'cabinet_shop':
    return <CabinetShopLocationDetail selectedLocation={selectedLocation as Location} />;
  case 'headquarters':
    return <HeadquartersLocationDetail selectedLocation={selectedLocation as Location} />;
  default:
    console.error("Unknown location type: ", selectedLocation);
    return;
  }
}

const categories = [{
  label: 'Projects',
  value: 'current_projects',
}, {
  label: 'Cabinet Shops',
  value: 'cabinet_shops',
}, {
  label: 'Headquarters',
  value: 'headquarters',
}, {
  label: 'Vendors',
  value: 'vendors',
}];

const ProjectLocationMarker: React.FC<{ location: ProjectLocation, onClick: () => void }> = ({ location, onClick }) => {
  const { project } = location;

  let pinColor = COLORS[project?.projectType]['DEFAULT'] ?? undefined;

  return (
    <Marker
      color={pinColor}
      longitude={location.address.lng}
      latitude={location.address.lat}
      anchor="bottom"
      key={`marker-${project.id}`}
      onClick={onClick}
    >
      <span className="cursor-pointer">
        <ProjectTypeBadge projectType={project.projectType} />
      </span>
    </Marker>
  );
};

const VendorLocationMarker: React.FC<{ location: VendorLocation, onClick: () => void }> = ({ location, onClick }) => {
  return (
    <Marker
      longitude={location.address.lng}
      latitude={location.address.lat}
      anchor="bottom"
      key={`marker-${location.id}`}
      onClick={onClick}
    >
      <div className="cursor-pointer bg-gray-700 rounded h-6 w-6 flex items-center justify-center">
        <ShoppingCartIcon className="h-4 w-4 text-gray-100" />
      </div>
    </Marker>
  );
};

const CabinetShopLocationMarker: React.FC<{ location: Location, onClick: () => void }> = ({ location, onClick }) => {
  return (
    <Marker
      color="green"
      longitude={location.address.lng}
      latitude={location.address.lat}
      anchor="bottom"
      key={`marker-${location.id}`}
      onClick={onClick}
    >
      <div className="cursor-pointer bg-white rounded h-6 w-6 flex items-center justify-center">
        <Submark className="h-4 w-4 text-plum" />
      </div>
    </Marker>
  );
};

const HeadquartersLocationMarker: React.FC<{ location: Location, onClick: () => void }> = ({ location, onClick }) => {
  return (
    <Marker
      color="red"
      longitude={location.address.lng}
      latitude={location.address.lat}
      anchor="bottom"
      key={`marker-${location.id}`}
      onClick={onClick}
    >
      <div className="cursor-pointer bg-white rounded h-6 w-6 flex items-center justify-center">
        <Submark className="h-4 w-4 text-plum" />
      </div>
    </Marker>
  );
};

const LocationMarker: React.FC<{ location: CraftworkLocation, onClick: () => void }> = ({ location, onClick }) => {
  if (!location?.address) return null;
  if (!location.address?.lng) return null;
  if (!location.address?.lat) return null;

  switch (location.locationType) {
  case 'customer':
    return <ProjectLocationMarker location={location as ProjectLocation} onClick={onClick} />;
  case 'vendor_location':
    return <VendorLocationMarker location={location as VendorLocation} onClick={onClick} />;
  case 'cabinet_shop':
    return <CabinetShopLocationMarker location={location as Location} onClick={onClick} />;
  case 'headquarters':
    return <HeadquartersLocationMarker location={location as Location} onClick={onClick} />;
  default:
    return;
  }
};

type CraftworkLocation = ProjectLocation | VendorLocation | Location;

export const ProjectsMap: React.FC<ServiceAreaMapProps> = ({
  start,
  end,
  style,
  zoom = 8.75,
  mapboxToken,
}) => {
  const [locations, setLocations] = useState<CraftworkLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<CraftworkLocation | null>(null);
  const [showControls, setShowControls] = useState(false);

  const [visibility, setVisibility] = useState(() => {
    const storedVisibility = localStorage.getItem('location_visibility');
    if (storedVisibility) {
      return JSON.parse(storedVisibility);
    }

    return {
      current_projects: true,
      cabinet_shops: true,
      headquarters: true,
      vendors: true,
    };
  });

  useEffect(() => {
    // Store these settings for later
    localStorage.setItem('location_visibility', JSON.stringify(visibility));

    // Filter out the location types that shouldn't be visible
    const params: any = Object
      .keys(visibility)
      .filter((key) => visibility[key])
      .reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});

    // Include the start and end dates so we can fetch the projects happening
    // within this range.
    params['start'] = start;
    params['end'] = end;

    // Build a query string
    const query = new URLSearchParams(params).toString();

    // Fetch the locations, this only stores the locations, but doesn't convert
    // them to markers, yet.
    fetch(`/api/v1/locations?${query}`)
      .then((response) => response.json())
      .then((data) => {
        setLocations(data);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLocations([]);
      });
  }, [visibility]);

  const onVisibilityChange = (value: string) => {
    setVisibility((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  return (
    <div className="relative inset-0 overflow-hidden h-full">
      <Map
        style={{
          width: '100%',
          ...style,
          height: '100%',
          // minHeight: '300px',
        }}
        initialViewState={{
          bounds: [
            [-81.079389, 35.577275],
            [-80.552366, 35.001212],
          ],
          latitude: 35.2892435,
          longitude: -80.8158775,
          zoom,
        }}
        mapStyle="mapbox://styles/craftworkmike/clj4jgqts00rf01r1g28p9q9c"
        mapboxAccessToken={mapboxToken}
      >
        <Source
          id="my-data"
          type="vector"
          url="mapbox://craftworkmike.cljywxwqe8p6f2onqevc6y9am-9jvz0"
        >
          <Layer {...dataLayer} source-layer="Charlotte_Service_Area_-_by_zip_" />
          <Layer
            {...labelLayer}
            source-layer="Charlotte_Service_Area_-_by_zip_"
          />

          {locations.map((location) => (
            <LocationMarker
              key={location.id}
              location={location}
              onClick={() => {
                setSelectedLocation(null);
                setTimeout(() => setSelectedLocation(location), 0);
              }}
            />
          ))}

          {selectedLocation && (
            <Popup
              anchor="top"
              className="rounded-md p-0"
              longitude={Number(selectedLocation.address.lng)}
              latitude={Number(selectedLocation.address.lat)}
              onClose={() => setSelectedLocation(null)}
            >
              <LocationDetail selectedLocation={selectedLocation} />
            </Popup>
          )}
        </Source>
      </Map>
      <div className="hidden md:block bg-white bg-opacity-80 absolute top-4 right-4 p-2 rounded-lg shadow-dark">
        {showControls ? (
          <div>
            <div className="flex">
              <h3 className="p-2 text-gray-500">Points of interest</h3>
              <button onClick={() => setShowControls(false)}>
                <EllipsisVerticalIcon className="h-6 w-6 text-gray-500" />
              </button>
            </div>
            {categories.map(({ label, value }) => (
              <div className="form-control flex items-center gap-2 p-2 min-w-max" key={value}>
                <input type="checkbox" id={value} name={value} checked={visibility[value]} onChange={(e) => onVisibilityChange(value)} />
                <label htmlFor={value}>{label}</label>
              </div>
            ))}
          </div>) :
          <button onClick={() => setShowControls(true)}>
            <EllipsisVerticalIcon className="h-6 w-6 text-gray-500" />
          </button>
        }
      </div>
    </div>
  );
};

export default ProjectsMap;
