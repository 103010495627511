import React from 'react';
import { clsxm } from '../../utils/clsxm';

const baseClasses =
  'flex h-8 w-8 items-center justify-center rounded-full bg-white shrink-0 transition-all duration-300 hover:z-0';
const innerClasses =
  'flex items-center justify-center w-[85%] h-[85%] rounded-full bg-gray-300 text-white leading-0 font-base-bold';

const ImageAvatar = ({ user, classes, tooltips = true }) => {
  return (
    <img
      src={user.avatarUrl}
      alt={'name'}
      className={`${baseClasses} ${classes}`}
      data-controller={tooltips && 'tooltip'}
      data-tippy-content={`${user.firstName} ${user.lastName[0]}.`}
      data-tippy-delay={'100ms'}
    />
  );
};

const InitialsAvatar = ({ user, classes, tooltips = true }) => {
  const initials = user.firstName.charAt(0) + user.lastName.charAt(0);
  return (
    <div
      className={`${baseClasses} ${classes}`}
      data-controller={tooltips && 'tooltip'}
      data-tippy-content={`${user.firstName} ${user.lastName[0]}.`}
      data-tippy-delay={'100ms'}
    >
      <div className={innerClasses}>{initials}</div>
    </div>
  );
};

export const CountAvatar = ({ users, classes }) => {
  const count = users.length;
  const names = users
    .map((user) => `${user.firstName} ${user.lastName[0]}.`)
    .join(', ');
  return (
    <div
      className={clsxm(baseClasses, classes)}
      data-controller="tooltip"
      data-tippy-content={names}
      data-tippy-delay={'100ms'}
    >
      <div className={innerClasses}>+{count}</div>
    </div>
  );
};

export const Avatar = ({ user, classes = '', tooltips = true }) => {
  if (user.avatarUrl?.includes('active_storage')) {
    return <ImageAvatar user={user} classes={classes} tooltips={tooltips} />;
  } else {
    return <InitialsAvatar user={user} classes={classes} tooltips={tooltips} />;
  }
};

export const AvatarGroup = ({ users, classes = '' }) => {
  const maxLength = 4;
  const filteredUsers = users;
  const additionalUsers = users.splice(
    maxLength - 1,
    users.length - maxLength + 1,
  );

  if (users.length > maxLength) {
    filteredUsers.length = maxLength;
    filteredUsers.push({ name: `+${users.length - maxLength}` });
  }
  return (
    <div className={clsxm('group isolate flex justify-center gap-1', classes)}>
      {filteredUsers.map((user, i) => {
        return (
          <Avatar
            key={i}
            user={user}
            classes={i !== 0 ? `-ml-3 group-hover:-ml-1` : ''}
          />
        );
      })}
      {additionalUsers.length > 0 && (
        <CountAvatar
          users={additionalUsers}
          classes="-ml-3 hover:z-0 group-hover:-ml-1 transition-all duration-300"
        />
      )}
    </div>
  );
};
