import React from 'react';

import { StackedDate } from '../ui/StackedDate';
import { WeatherDayIcon } from '../ui/WeatherDayIcon';
import { AvatarGroup } from '../ui/Avatars';

export const DayHeader = ({
  date,
  text,
  craftworkerOpenings,
  weatherDays,
  painterCount,
}) => {
  const day = text.split(' ')[0];
  const weekday = text.split(' ')[1];
  const key = date.toISOString().split('T')[0];

  const dailyCraftworkerOpenings = craftworkerOpenings.filter(({ start }) => {
    return start.split('T')[0] === key;
  });

  const weatherDay = weatherDays.find(({ day }) => day === key);

  const openCraftworkerOpeningsModal = () => {
    Turbo.visit(
      `/calendar_events?craftworker_openings=true&modal=true&start=${date}&end=${date}`,
      {
        frame: 'modal',
      },
    );
  };

  const datePainterCount = painterCount.find(({ date }) => date === key);

  const availableCraftworkers = dailyCraftworkerOpenings.map(
    (cwo) => cwo.extendedProps.craftworker,
  );

  const rawStaffedPercentage = Math.round(
    (1 -
      (dailyCraftworkerOpenings.length ?? 0) / (datePainterCount?.count ?? 0)) *
      100,
  );

  const staffedPercentage = isNaN(rawStaffedPercentage)
    ? 0
    : rawStaffedPercentage;

  let progressColor;

  console.log('staffedPercentage', staffedPercentage)

  if (staffedPercentage > 0 && staffedPercentage < 33) {
    progressColor = 'bg-warning';
  } else if (staffedPercentage >= 33 && staffedPercentage < 66) {
    progressColor = 'bg-orange-500';
  } else if (staffedPercentage >= 66 && staffedPercentage < 100) {
    progressColor = 'bg-caution';
  } else if (staffedPercentage >= 100) {
    progressColor = 'bg-success';
  } else {
    progressColor = 'bg-transparent';
  }

  return (
    <div className="flex w-full flex-col divide-y">
      {/* Top */}
      <div className="flex h-10 items-center justify-between">
        <StackedDate weekday={weekday} day={day} />
        <WeatherDayIcon weatherDay={weatherDay} />
      </div>

      {/* Bottom */}
      <div className="flex w-full flex-col items-center justify-center gap-1 text-sm font-normal">
        {dailyCraftworkerOpenings.length > 0 ? (
          <button
            onClick={openCraftworkerOpeningsModal}
            className="flex h-10 w-full cursor-pointer items-center justify-center"
          >
            <AvatarGroup users={availableCraftworkers} />
          </button>
        ) : (
          <div className="flex h-10 w-full items-center justify-center text-sm leading-none text-gray-500">
            Fully Staffed
          </div>
        )}
        <div className="h-1 w-full rounded bg-gray-200">
          <span
            style={{ width: `${staffedPercentage}%` }}
            className={`${progressColor} block h-full rounded`}
          ></span>
        </div>
      </div>
    </div>
  );
};
