import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["address", "placeId"]

  connect() {
    console.log("DraftEstimateFormController connected")
    this.element.addEventListener('addressSelected', this.handleAddressSelected.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('addressSelected', this.handleAddressSelected.bind(this))
  }

  handleAddressSelected(event) {
    const place = event.detail
    console.log('Address selected:', place)

    if (this.hasAddressTarget && place.formatted_address) {
      this.addressTarget.value = place.formatted_address
    }

    if (this.hasPlaceIdTarget && place.place_id) {
      this.placeIdTarget.value = place.place_id
    }
  }
}
