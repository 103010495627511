import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['record', 'sidebar'];
  static values = {
    currentRecord: String,
    isMobile: Boolean,
    mobileBreakpoint: Number,
  };

  connect() {
    // get intial record id from params if it exists
    this.updateCurrentRecordValue();
    // Set the mobile breakpoint value to coinside with the lg: tailwind breakpoint
    this.mobileBrakePointValue = 990;
    // Bind the updateIsMobile method to this controller instance
    this.updateIsMobile = this.updateIsMobile.bind(this);
    window.addEventListener('resize', this.updateIsMobile);
    // Immediately update isMobileValue upon connection
    this.updateIsMobile();
  }

  disconnect() {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  updateCurrentRecordValue() {
    const params = new URLSearchParams(window.location.search);
    const currentRecordId = params.get('selected_record_id'); // update controllers to show current_record_id and then no need to pass this anymore
    if (this.currentRecordValue !== currentRecordId) {
      this.currentRecordValue = currentRecordId;
    }
  }

  recordTargetConnected(element) {
    const params = new URLSearchParams(window.location.search);
    if (element.id === params.get('selected_record_id')) {
      this.setActiveClasses(element);
    } 
  }

  selectRecord(event) {
    const record = this.recordTargets.find(
      (record) => record === event.currentTarget,
    );
    this.currentRecordValue = record.id;
    this.updateRecordStyles();
  }

  updateRecordStyles() {
    this.recordTargets.forEach((record) => {
      const recordId = record.id;

      if (recordId === this.currentRecordValue) {
        this.setActiveClasses(record);
      } else {
        this.setInactiveClasses(record);
      }
    });
  }

  setActiveClasses(record) {
    const previewText = record.querySelector('[data-preview-text]');
    previewText.classList.add('text-white/70');
    record.classList.add('bg-plum');
    record.classList.add('text-white');
    record.classList.remove('hover:bg-gray-50');
  }

  setInactiveClasses(record) {
    record.classList.add('hover:bg-gray-50');
    const previewText = record.querySelector('[data-preview-text]');
    previewText.classList.remove('text-white/70');
    record.classList.remove('bg-plum');
    record.classList.remove('text-white');
  }

  toggleMobileInboxNav() {
    const mobileInboxNav = document.querySelector('#mobile-inbox-nav');
    mobileInboxNav.classList.toggle('max-w-0');
  }

  showSidebar() {
    if (!this.isMobileValue) return;
    this.sidebarTarget.classList.remove('-translate-x-full');
  }

  hideSidebar() {
    if (!this.isMobileValue) return;
    this.sidebarTarget.classList.add('-translate-x-full');
  }

  updateIsMobile() {
    const isMobile = window.innerWidth < this.mobileBrakePointValue;
    this.isMobileValue = isMobile;
    if (
      !isMobile &&
      this.sidebarTarget.classList.contains('-translate-x-full')
    ) {
      this.sidebarTarget.classList.remove('-translate-x-full');
    }
  }
}
