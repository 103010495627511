import React from 'react';

type ProjectTypeBadgeProps = {
  projectType: 'interior' | 'exterior' | 'cabinets' | 'specialty';
};

const iconClasses = 'w-4 h-4 shrink-0 hidden @4xs:block';

const Icon = {
  Exterior: () => (
    <svg viewBox="0 0 22 22" fill="currentColor" className={iconClasses}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.621 0.725323C18.7727 1.11075 18.5832 1.54619 18.1978 1.6979L17.1346 2.11637V7.97345L21.2548 9.46127C21.6443 9.60195 21.8461 10.0318 21.7054 10.4214C21.5647 10.811 21.1349 11.0128 20.7453 10.8721L20.2116 10.6794V20.25H21C21.4142 20.25 21.75 20.5858 21.75 21C21.75 21.4142 21.4142 21.75 21 21.75H1.00002C0.585804 21.75 0.250018 21.4142 0.250018 21C0.250018 20.5858 0.585804 20.25 1.00002 20.25H1.78848V8.16222L1.27486 8.36451C0.889459 8.5163 0.453981 8.32692 0.30219 7.94152C0.1504 7.55613 0.339778 7.12065 0.725177 6.96886L1.78848 6.55007V1.00002C1.78848 0.585804 2.12427 0.250018 2.53848 0.250018H5.6154C6.02962 0.250018 6.3654 0.585804 6.3654 1.00002V4.74727L17.6484 0.302133C18.0338 0.150423 18.4693 0.339892 18.621 0.725323ZM4.8654 5.33822L3.28848 5.9593V1.75002H4.8654V5.33822ZM3.28848 7.57144V20.25H6.40386V15.5834C6.40386 14.5368 7.20118 13.5834 8.30771 13.5834H10.6154C11.7219 13.5834 12.5192 14.5368 12.5192 15.5834V20.25H15.6346V8.5174C15.6344 8.50581 15.6344 8.49419 15.6346 8.48255V2.70721L3.28848 7.57144ZM17.1346 9.56825V20.25H18.7116V10.1377L17.1346 9.56825ZM7.90386 20.25H11.0192V15.5834C11.0192 15.2499 10.7827 15.0834 10.6154 15.0834H8.30771C8.14039 15.0834 7.90386 15.2499 7.90386 15.5834V20.25Z"
      />
    </svg>
  ),
  Interior: () => (
    <svg viewBox="0 0 24 24" fill="currentColor" className={iconClasses}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 3C1.25 2.0335 2.0335 1.25 3 1.25H21C21.9665 1.25 22.75 2.0335 22.75 3V21C22.75 21.9665 21.9665 22.75 21 22.75H3C2.0335 22.75 1.25 21.9665 1.25 21V3ZM3 2.75C2.86193 2.75 2.75 2.86193 2.75 3V19.1389H7.25V8.80555C7.25 7.83906 8.0335 7.05556 9 7.05556H15C15.9665 7.05556 16.75 7.83906 16.75 8.80556V19.1389H21.25V3C21.25 2.86193 21.1381 2.75 21 2.75H3ZM21.25 20.6389H16.75V21.25H21C21.1381 21.25 21.25 21.1381 21.25 21V20.6389ZM15.25 21.25V8.80556C15.25 8.66748 15.1381 8.55556 15 8.55556H9C8.86193 8.55556 8.75 8.66748 8.75 8.80555V21.25H15.25ZM7.25 21.25V20.6389H2.75V21C2.75 21.1381 2.86193 21.25 3 21.25H7.25Z"
      />
    </svg>
  ),
  Cabinets: () => (
    <svg viewBox="0 0 24 24" fill="currentColor" className={iconClasses}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 2C2.25 1.0335 3.0335 0.25 4 0.25H20C20.9665 0.25 21.75 1.0335 21.75 2V22C21.75 22.9665 20.9665 23.75 20 23.75H4C3.0335 23.75 2.25 22.9665 2.25 22V2ZM4 1.75C3.86193 1.75 3.75 1.86193 3.75 2V11.25H20.25V2C20.25 1.86193 20.1381 1.75 20 1.75H4ZM20.25 12.75H3.75V22C3.75 22.1381 3.86193 22.25 4 22.25H20C20.1381 22.25 20.25 22.1381 20.25 22V12.75ZM6.75 6.2381C6.75 5.82388 7.08579 5.4881 7.5 5.4881H16.5C16.9142 5.4881 17.25 5.82388 17.25 6.2381C17.25 6.65231 16.9142 6.9881 16.5 6.9881H7.5C7.08579 6.9881 6.75 6.65231 6.75 6.2381ZM6.75 17.7619C6.75 17.3477 7.08579 17.0119 7.5 17.0119H16.5C16.9142 17.0119 17.25 17.3477 17.25 17.7619C17.25 18.1761 16.9142 18.5119 16.5 18.5119H7.5C7.08579 18.5119 6.75 18.1761 6.75 17.7619Z"
      />
    </svg>
  ),
  Specialty: () => (
    <svg viewBox="0 0 24 24" fill="currentColor" className={iconClasses}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 2C2.25 1.0335 3.0335 0.25 4 0.25H20C20.9665 0.25 21.75 1.0335 21.75 2V22C21.75 22.9665 20.9665 23.75 20 23.75H4C3.0335 23.75 2.25 22.9665 2.25 22V2ZM4 1.75C3.86193 1.75 3.75 1.86193 3.75 2V11.25H20.25V2C20.25 1.86193 20.1381 1.75 20 1.75H4ZM20.25 12.75H3.75V22C3.75 22.1381 3.86193 22.25 4 22.25H20C20.1381 22.25 20.25 22.1381 20.25 22V12.75ZM6.75 6.2381C6.75 5.82388 7.08579 5.4881 7.5 5.4881H16.5C16.9142 5.4881 17.25 5.82388 17.25 6.2381C17.25 6.65231 16.9142 6.9881 16.5 6.9881H7.5C7.08579 6.9881 6.75 6.65231 6.75 6.2381ZM6.75 17.7619C6.75 17.3477 7.08579 17.0119 7.5 17.0119H16.5C16.9142 17.0119 17.25 17.3477 17.25 17.7619C17.25 18.1761 16.9142 18.5119 16.5 18.5119H7.5C7.08579 18.5119 6.75 18.1761 6.75 17.7619Z"
      />
    </svg>
  ),
};

const ProjectTypeBadge = ({ projectType }: ProjectTypeBadgeProps) => {
  const baseClass = 'h-6 w-2 @4xs:w-6 flex items-center justify-center gap-2 rounded text-white font-base-medium text-sm';
  switch (projectType) {
    case 'interior':
      return (
        <span className={`${baseClass} bg-interior`}>
          <Icon.Interior />
        </span>
      );
    case 'exterior':
      return (
        <span className={`${baseClass} bg-exterior`}>
          <Icon.Exterior />
        </span>
      );
    case 'cabinets':
      return (
        <span className={`${baseClass} bg-cabinets`}>
          <Icon.Cabinets />
        </span>
      );
    case 'specialty':
      return (
        <span className={`${baseClass} bg-specialty`}>
          <Icon.Specialty />
        </span>
      );
    default:
      return null;
  }
};

export default ProjectTypeBadge;
