import consumer from "./consumer"

const initProjectViewCable = () => {
  const projectElement = document.getElementById('viewing-users')
  if (projectElement) {
    const projectId = projectElement.dataset.projectId

    consumer.subscriptions.create({ channel: "ProjectViewChannel", project_id: projectId }, {
      received(data) {
        if (data.action === 'joined') {
          addUserToViewList(data.user)
        } else if (data.action === 'left') {
          removeUserFromViewList(data.user)
        }
      }
    })
  }
}

const addUserToViewList = (user) => {
  const userList = document.getElementById('viewing-users')

  // Check to make sure they are not already in the list.
  if (document.getElementById(`viewer-${user.id}`)) {
    return;
  }
  // No need to show the current user they are viewing.
  if (user.id == window.currentUserId) {
    return;
  }

  const userElement = document.createElement('li')

  userElement.setAttribute('class', '-ml-3 z-n8 group-hover:-ml-1 hover:z-0 transition-all duration-300')
  const initials = `${user.first_name[0]}${user.last_name[0]}`
  userElement.innerHTML = `
    <div data-controller="tooltip" data-tippy-content="${user.first_name} is viewing" class="w-8 h-8 flex items-center justify-center shrink-0 border rounded-full cursor-pointer bg-white shadow-sm hover:transition-scale duration-300 hover:scale-[1.2]">
      <div class="flex items-center justify-center w-[85%] h-[85%] rounded-full bg-gray-300 text-white leading-0 font-base-bold text-xs">
        ${initials}
      </div>
    </div>
  `
  userElement.id = `viewer-${user.id}`
  userList.appendChild(userElement)
}

const removeUserFromViewList = (user) => {
  const userElement = document.getElementById(`viewer-${user.id}`)
  if (userElement) {
    userElement.remove()
  }
}

document.addEventListener('turbo:load', initProjectViewCable)
