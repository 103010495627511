import { Controller } from "@hotwired/stimulus"
import { Draggable } from '@shopify/draggable';

// Connects to data-controller="teams"
export default class extends Controller {
  static targets = ['team']
  hoverTeam;

  connect() {
    this.draggable = new Draggable(this.element, {
      handle: '.item',
      draggable: '.item',
      distance: 10,
      mirror: {
        appendTo: "body",
        constrainDimensions: true
      }
    });

    this.draggable.on('drag:start', (e) => {
      e.data.source.classList.add('shadow', "border-plum/40", "border");
    });
    this.draggable.on('drag:move', (e) => this.dragMove(e));
    this.draggable.on('drag:stop', async (e) => await this.dragStop(e));
  }

  dragMove(e) {
    const team = this.teamTargets.find((t) => t.contains(e.data.sensorEvent.target));
    if (team) {
      this.hoverTeam = team;
    } else {
      this.hoverTeam = null;
    }
  }

  async dragStop(event) {
    const userId = event.source.dataset.id;
    const newTeamId = this.hoverTeam?.dataset?.teamId;

    // API call to update the team membership
    fetch('/team_memberships', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
        'Accept': 'text/vnd.turbo-stream.html',
      },
      body: JSON.stringify({
        team_membership: {
          user_id: userId,
          team_id: newTeamId
        }
      })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    }).then(html => {
      Turbo.renderStreamMessage(html);
    }).catch(error => {
      console.error('Failed to update team membership:', error);
    });
  }
}
