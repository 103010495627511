// app/javascript/controllers/expandable_controller.js
import { Controller } from "@hotwired/stimulus";
import {enter, leave, toggle} from "el-transition";

export default class extends Controller {
  static targets = ["header", "body", "icon"];

  connect() {
    // Check for the 'defaultOpen' attribute to set the initial state
    if (this.data.has("defaultOpen")) {
      this.expand();
    } else {
      this.collapse();
    }

    // Add a global click event listener
    document.addEventListener("click", this.handleDocumentClick.bind(this));
  }

  disconnect() {
    // Remove the global click event listener when the controller is disconnected
    document.removeEventListener("click", this.handleDocumentClick.bind(this));
  }

  toggle() {
    if (this.bodyTarget.classList.contains("hidden")) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand() {
    enter(this.bodyTarget)
    // this.bodyTarget.classList.remove("hidden");
    this.hasIconTarget && this.iconTarget.classList.add("rotate-180");
  }

  collapse() {
    leave(this.bodyTarget)
    // this.bodyTarget.classList.add("hidden");
    this.hasIconTarget && this.iconTarget.classList.remove("rotate-180");
  }

  handleDocumentClick(event) {
    // Check if the clicked element is not within the current expandable container
    if (!this.element.contains(event.target) && this.data.has("shouldBlur")) {
      this.collapse();
    }
  }
}
