import React from 'react';
import { Avatar } from '../ui/Avatars'
import { ProgressBar } from "./ProgressBar";
import { clsxm } from "../../utils/clsxm";

const sum = (arr) => arr.reduce((acc, curr) => acc + curr, 0);

export const ResourceLabel = ({args, isCurrentWeek, scopedHours}) => {
  const { firstName, avatarUrl, lastName } = args.resource.extendedProps;
  const id = args.resource.id;

  // Get the events in range for just this craftworker:
  const events = args.resource.getEvents();
  // Calculate stats.
  const hoursWorked = sum(
    events.map((evt) => evt.extendedProps.totalTimesheetHours),
  ).toFixed(1);
  const hoursBooked = sum(
    events.map((evt) => evt.extendedProps.totalShiftHours),
  ).toFixed(1);
  const ptoHours = sum(
    events
      .filter((evt) => evt.extendedProps.pto)
      .map((evt) => evt.extendedProps.totalShiftHours),
  ).toFixed(1);

  // TODO(drew): Add un paid time off hours? `evt.extendedProps.unPto`


  const user = {
    firstName,
    lastName,
    avatarUrl,
    id,
  };

  const hoursStatusClasses = () => {
    const hours = isCurrentWeek ? parseInt(hoursBooked) : parseInt(hoursWorked);
    if (hours < scopedHours) {
      return 'text-caution';
    } else if (hours > scopedHours) {
      return 'text-warning';
    } else {
      return 'text-plum';
    }
  };

  return (
    <div className="flex gap-2">
      <div className="flex flex-col items-center gap-2">
        <a href={`/craftworkers/${id}`} className="flex shrink-0">
          <Avatar user={user} />
        </a>
        <div className="flex gap-1 text-xs font-base-medium text-gray-400">
          <span className={hoursStatusClasses()}>{isCurrentWeek ? hoursBooked : hoursWorked}</span>
          <span>/</span>
          <span>{scopedHours}</span>
        </div>
      </div>
      <div className="flex flex-col gap-[11px] flex-1 mt-[6px] overflow-hidden">
        <div className="truncate font-base-bold">{args.resource.title}</div>
        <ProgressBar
          hoursDisplay={isCurrentWeek ? hoursBooked : hoursWorked}
          ptoHours={ptoHours}
          scopedHours={scopedHours}
        />
      </div>
    </div>
  );
};
