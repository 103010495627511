import React from 'react';
import ActivityCal from 'react-activity-calendar';
import type { Activity, ColorScale, ThemeInput } from 'react-activity-calendar';
import { purple } from 'tailwindcss/colors';

type ActivityCalendarProps = {
  activities: Activity[];
  label?: string;
  maxLevel: number;
};

export const ActivityCalendar: React.FC<ActivityCalendarProps> = ({
  activities,
  maxLevel,
  label = 'activities',
}) => {
  if (!activities) {
    console.error('No activity data provided');
    return null;
  }

  console.log('mx', maxLevel);

  const theme: ThemeInput = {
    light: ['#fff', '#32213B'],
    dark: ['#383838', '#32213B'],
  };

  console.log('activity', activities.length);
  return (
    <ActivityCal
      theme={theme}
      maxLevel={maxLevel}
      labels={{ totalCount: `{{count}} ${label} in the last year` }}
      data={activities}
      renderBlock={(block, activity) => {
        return React.cloneElement(block, {
          'data-controller': 'tooltip',
          'data-tippy-content': `${activity.count} on ${new Date(activity.date).toLocaleDateString()}`,
        });
      }}
    />
  );
};

export default ActivityCalendar;
