import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['english', 'spanish'];

  connect() {
  }

  toggle(event) {
    const spanish = event.target.checked;
    if (spanish) {
      enter(this.spanishTarget, 'fade');
      leave(this.englishTarget, 'fade');
    } else {
      enter(this.englishTarget, 'fade');
      leave(this.spanishTarget, 'fade');
    }
  }
}
