import Chart from 'chart.js/auto';
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pie-chart"
export default class extends Controller {
  connect() {
    this.ctx = this.element.getContext("2d");

    const labelToValue = JSON.parse(this.ctx.canvas.dataset.data)
    const metrics = Object.values(labelToValue)
    const labels = Object.keys(labelToValue)

    const typeToColor = {
      'Interior': '#6A397E',
      'Exterior': '#2874A6',
      'Specialty': '#39767E',
      'Cabinets': '#5D6D7E'
    }

    const data = {
      labels,
      datasets: [{
        data: metrics,
        backgroundColor: labels.map(label => typeToColor[label]),
        hoverOffset: 4
      }]
    };

    this.chart = new Chart(this.ctx, {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true // this should be set to make the bars stacked
          },
          y: {
            stacked: true // this also..
          }
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: this.ctx.canvas.dataset.title
          }
        }
      }
    });

  }
}
