// Customizable command palette for advanced users
// Opens with cmd+k or ctrl+k by default
// https://github.com/excid3/ninja-keys

import { Controller } from "@hotwired/stimulus"
import debounce from 'lodash.debounce';
import "@gorails/ninja-keys"

export default class extends Controller {
  connect() {
    const controller = this;
    const defaultActions = [
      {
        id: "Projects",
        title: "Projects",
        hotkey: "ctrl+P",
        children: [],
        icon: `<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="ninja-icon">
  <path d="M17.25 22.5H6.75C5.95 22.5 5.2 22.19 4.63 21.62C4.06 21.05 3.75 20.3 3.75 19.5V6.26C3.75 4.76 4.85 3.5 6.32 3.33C6.81 3.27 7.31 3.22 7.81 3.18C8.31 2.17 9.35 1.5 10.5 1.5H13.5C14.15 1.5 14.79 1.72 15.31 2.11C15.68 2.39 15.99 2.76 16.19 3.18C16.69 3.22 17.18 3.27 17.68 3.33C19.15 3.5 20.25 4.76 20.25 6.26V19.5C20.25 20.3 19.94 21.05 19.37 21.62C18.8 22.19 18.05 22.5 17.25 22.5ZM7.52 4.71C7.18 4.74 6.84 4.78 6.5 4.82C5.79 4.9 5.26 5.52 5.26 6.26V19.5C5.26 19.9 5.42 20.28 5.7 20.56C5.98 20.84 6.36 21 6.76 21H17.26C17.66 21 18.04 20.84 18.32 20.56C18.6 20.28 18.76 19.9 18.76 19.5V6.26C18.76 5.52 18.22 4.9 17.52 4.82C17.18 4.78 16.84 4.75 16.5 4.71C16.45 5.03 16.3 5.33 16.08 5.56C15.8 5.84 15.42 6 15.02 6H9.02C8.62 6 8.24 5.84 7.96 5.56C7.73 5.33 7.58 5.03 7.54 4.71H7.52ZM9.06 4.1C9.02 4.23 9.01 4.36 9.01 4.5H15.01C15.01 4.37 14.99 4.23 14.96 4.11C14.96 4.11 14.96 4.09 14.96 4.08C14.87 3.77 14.68 3.5 14.43 3.3C14.17 3.1 13.85 3 13.52 3H10.52C9.86 3 9.27 3.44 9.08 4.08V4.11L9.06 4.1Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17 15.2H9.89V12.81L12.6 11.25L17 13.69V10.45L12.59 8.01001L7 11.24V18.01H17V15.2Z" />
</svg>`,
        handler: () => {
          Turbo.visit("/projects/list")
        }
      },
      {
        id: "recentProjects",
        title: "Recently Viewed",
        hotkey: "ctrl+R",
        children: [],
        icon: `<svg class="ninja-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>`,
        handler: async () => {
          const recentProjects = defaultActions.find(action => action.id === "recentProjects");

          this.element.open({ parent: "recentProjects" });

          (await controller.fetchRecent()).forEach((result) => {
            if (!defaultActions.find(action => action.id === result.id)) {
              defaultActions.push({
                ...result,
                parent: "recentProjects",
                handler: () => {
                  Turbo.visit(result.url);
                }
              })
            }
          })

          const changedProperties = new Map();
          changedProperties.set("data", defaultActions);
          this.element.update(changedProperties);
        }
      },
      {
        id: "Customers",
        title: "Customers",
        hotkey: "ctrl+A",
        icon: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
        children: [],
        handler: () => {
          Turbo.visit("/customers")
        }
      },
      {
        id: "Calendar",
        title: "Calendar",
        hotkey: "ctrl+C",
        children: [],
        icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="ninja-icon">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
        </svg>`,
        handler: () => {
          Turbo.visit("/project_calendars")
        }
      },
    ];

    const typeIcons = {
      Project: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>',
      Account: '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
      Calendar: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="ninja-icon h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
        </svg>
      `,
    }

    // Set the default options.
    this.element.data = defaultActions;

    const search = debounce(async (query) => {
      const {results} = await fetch(`/api/v1/search?q=${query}`).then(r => r.json())
      this.element.data = results.map((result) => {
        return {
          ...result,
          title: result.title || `Unknown ${result.type}`,
          icon: typeIcons[result.type],
          handler: () => {
            Turbo.visit(result.path);
          }
        }
      })
    }, 70)

    // When the user types into the search box, fire off queries.
    this.element.addEventListener('change', async (event) => {
      if(event.detail.search === "") {
        this.element.data = defaultActions;
      } else {
        search(event.detail.search)
      }
    })
  }

  async fetchRecent() {
    return await fetch("/api/v1/search/recent").then(r => r.json())
  }
}
